export const Orientations={
    portrait: "CARD_ORIENTATION_PORTRAIT",
    landscape:"CARD_ORIENTATION_LANDSCAPE"
}

export const available_send_dates = [
    // {
    //     "send_date":"30-Oct-2024",
    //     "drop_date":"27-Oct-2024"
    // },
    {
        "send_date":"Nov-06-2024",
        "drop_date":"Nov-03-2024"
    },
    {
        "send_date":"Nov-27-2024",
        "drop_date":"Nov-24-2024"
    },
    {
        "send_date":"Dec-04-2024",
        "drop_date":"Dec-01-2024"
    },
    {
        "send_date":"Dec-11-2024",
        "drop_date":"Dec-08-2024"
    },
    {
        "send_date":"Dec-16-2024",
        "drop_date":"Dec-11-2024"
    },
    // {
    //     "send_date":"18-Dec-2024",
    //     "drop_date":"15-Dec-2024"
    // },
    {
        "send_date":"Dec-23-2024",
        "drop_date":"Dec-18-2024"
    },
    {
        "send_date":"Jan-09-2025",
        "drop_date":"Jan-06-2025"
    }
]