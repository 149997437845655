import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../Global/Scroll"
import { errorMessages } from "../../../Constants/Errors";
import { order_page_labels, product_page_labels } from "../../../Constants/Labels";
import { countries } from "../../../Constants/Countries/countries";
import FileUpload from "../../FileUpload/FileUpload";
import { callAPI, useStore, useUser, usePage } from "../../../Utils/utils"
import { item_required_error_message, order_page_message, alert_message_type } from "../../../Constants/Messages";
import Pagination from "../../Global/Pagination/Pagination";
import SecondaryLoader from "../../Global/SecondaryLoader/SecondaryLoader";
import AddAddress from "../../Customer/AddAddress/AddAddress";
import EditAddress from "../../Customer/EditAddress/EditAddress";
import Alert from "../../Alert/Alert";
import ErrorMessage from "../../Global/ErrorMessage";
import { PopUpMessage } from "../../Global/PopupMessage/PopupMessage";
import Message from "../../Message/Message";
import "./AddOrder.css";
import { CardConfig } from "../../../Config/CardConfig"
import CardForm from "../CardFrom/CardForm";
import Loader from "../../Global/Loader/Loader";
import { available_send_dates } from "../../../Constants/Dates";
import moment from "moment";

export default function AddOrder() {
    const navigate = useNavigate();
    const { page, setPage } = usePage()
    const { user, setUser } = useUser();
    const [alert, setAlert] = useState()
    const [stock, setStock] = useState()
    const { store, setStore } = useStore()
    const [mode, setMode] = useState('list')
    const [errors, setErrors] = useState([])
    const [loading, setLoading] = useState(false)
    const [storeName, setStoreName] = useState([]);
    const [lineItems, setLineItems] = useState([])
    const [paymnetInfo, setPaymentInfo] = useState({})
    const [recipients, setRecipients] = useState([])
    const [searchValue, setSearchValue] = useState()
    const [customerList, setCustomerList] = useState()
    const [productList, setProductList] = useState()
    const [countryList, SetCountryList] = useState()
    const [allrecipientsSelectedCheckBox, setAllRecipientsSelectedCheckBox] = useState(false)
    const [returnAddress, setReturnAddress] = useState({})
    const [billingAddress, setBillingAddress] = useState({})
    const [refreshCount, setRefreshCount] = useState(1)
    const [productSearchValue, setProductSearchValue] = useState()
    const [customerSearchValue, setCustomerSearchValue] = useState()
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [selectedCustomer, setSelectedCustomer] = useState('')
    const [recipientError, setRecipientError] = useState('')
    const [selectedRecipient, setSelectedRecipient] = useState()
    const [selectedCustomerId, setSelectedCustomerId] = useState()
    const [selectedLineItemIndex, setSelectedLineItemIndex] = useState()
    const [customerAddressPayload, setCustomerAddressPayload] = useState()
    const [billingAddressFormValues, setBillingAddressFormValues] = useState({ country: "USA" })
    const [isSameAsReturnAddress, setIsSameAsReturnAddress] = useState(false)
    const [returnAddressFormvalues, setReturnAddressFormValues] = useState({})
    const [searchInProgress, setSearchInProgress] = useState(false)
    const [savecardFromsData, setSaveCardFromsData] = useState(0)
    const [newRecipientAddress, setNewRecipientAddress] = useState("")
    const [editRecipientAddress, setEditRecipientAddress] = useState(0)
    const [processingFeesCheckbox, setProcessingFeesCheckbox] = useState(false)
    const [processingFeesAmount, setProcessingFeesAmount] = useState(0)
    const [onOrderLoading, setOnOrderLoading] = useState(false);
    const [zeroRecipientsIndices, setZeroRecipientsIndices] = useState([]);
    const [selectedRecipientAddressIds, setSelectedRecipientAddressIds] = useState([]);
    const [donationAmount, setDonationAmonut] = useState(0);
    const [customerLoader, setCustomerLoader] = useState(false);
    const [productLoader, setProductLoader] = useState(false)
    const [allRecipientAddresses, setAllRecipientAddresses] = useState([]);
    const [requested_send_date, setRequestedSendDate] = useState()
    const [send_dates, setSendDates] = useState([])
    const [resultInfo, setResultInfo] = useState({
        total_rows: "",
        fetched_rows: "",
        total_pages: "",
        page_size: 0,
        current_page: "",
        success: true,
        errors: ""
    })

    const [itemSummary, setItemSummary] = useState(
        {
            subTotal: 0.00,
            tax: 0.00,
            total: 0.00
        }
    )

    useEffect(() => {
        let temp = []
        var minDate = new Date(new Date().setDate(new Date().getDate() + 2))
        if (available_send_dates?.length) {
            available_send_dates.forEach((each) => {
                if (minDate <= new Date(moment(each, "MMM-DD-YYYY").format("YYYY-MM-DD"))) {
                    temp.push(each)
                }
            })
        }
        setSendDates(temp)
    }, [])

    const onchangeDonationAmount = (event) => {
        const inputValue = event?.target?.value;
        const parsedValue = parseFloat(inputValue);
        setDonationAmonut(!isNaN(parsedValue) ? parsedValue.toFixed(2) : 0);
    };

    const onChangeProcessingFeesCheckbox = (event) => {
        if (event.target.checked) {
            setProcessingFeesCheckbox(true)
        }
        else {
            setProcessingFeesCheckbox(false)
        }
    }

    var payButton = true
    if (itemSummary?.total != 0 && paymnetInfo?.payment_mode == "PMNT-MD-STRIPE") {
        payButton = false
    } else if (itemSummary?.total != 0 && paymnetInfo?.payment_mode == "PMNT-MD-PAYPAL") {
        payButton = false
    } else if (itemSummary?.total != 0 && paymnetInfo?.payment_mode == undefined) {
        payButton = false
    } else {
        payButton = true
    }

    const modifyQuantity = (operation, index) => {
        const lineItemIndex = index;
        const lineItem = lineItems[lineItemIndex];
        if (operation == 'addition') {
            const newQuantity = lineItem.quantity + 1;
            lineItems[lineItemIndex] = { ...lineItem, quantity: newQuantity, total: lineItem.price_per_item * newQuantity };
        } else if (operation == 'subtraction') {
            if (lineItem.quantity > 1) {
                lineItems[lineItemIndex] = { ...lineItem, quantity: lineItem.quantity - 1, total: lineItem.price_per_item * (lineItem.quantity - 1) };
            }
        }
        setLineItems([...lineItems]);
    };

    useEffect(() => {
        if (storeName?.length == 0 || storeName == undefined) {
            setStoreName(() => [store?.name])
        }
        else {
            setStoreName((existingStoreId) => [...existingStoreId, store?.name])
        }
    }, [store?.id])

    useEffect(() => {
        if (storeName[1] != undefined) {
            if (storeName[0] != storeName[1]) {
                navigate("/admin")
                setStoreName([])
            }
        }
    }, [storeName])

    useEffect(() => {
        setPage({ isLoading: loading })
    }, [loading])

    const onChangeReturnAddressFormValues = (event) => {
        setReturnAddressFormValues({ ...returnAddressFormvalues, [event.target.name]: event.target.value });
        hideErrorMessage();
    }

    const onChangeBillingAddressFormValues = (event) => {
        setBillingAddressFormValues({ ...billingAddressFormValues, [event.target.name]: event.target.value })
        hideErrorMessage()
    }

    //get the product name during on change of the product input value 
    const onSearchProducts = (event) => {
        setProductSearchValue(event.target.value)
        getProductList(event.target.value)
        setMode('openProductListDropdown')

    }

    //get products list 
    const getProductList = (searchValue) => {
        setMode('openProductListDropdown')
        setSearchInProgress(true)
        setProductLoader(true)
        var Initial_pay_load = {
            store_id: store?.id,
            status: "active",
            page_size: 50,
            current_page: 1
        };

        var pay_load = searchValue
            ? ((Initial_pay_load.filters = {
                conditions: [
                    { field: "p.name", operator: "like", value: searchValue, dataType: "string" }
                ]
            }),
                Initial_pay_load)
            : Initial_pay_load;

        callAPI('/admin/products/by-store',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                },
                body: JSON.stringify(pay_load)
            }, navigate, setLoading, setStore)

            .then(data => {
                if (data.success) {
                    setProductList(data.records)
                    setProductLoader(false)
                }
            })
            .finally(() => {
                setSearchInProgress(false)
            }
            )
    }

    //get products list 
    const onClickProductList = () => {
        getProductList()
    }

    //get the selected product during on click
    const onClickSelectProduct = (product) => {
        // console.log("product", product)
        if (product) {
            var updatedLineItems = [...lineItems]
            //get the selected product list
            if (CardConfig?.cards_settings?.find(card => card?.product_type === product?.product_type)?.getRecipients) {
                updatedLineItems.push({ product_id: product.product_id, image_path: product.path, image_name: product.image_name, name: product.name, price_per_item: product.price, product_type: product.product_type, stock: product.stock, recipients: [], quantity: 0, total: 0, store_id: product.store_id })
            }
            if (CardConfig?.cards_settings?.find(card => card?.product_type === product?.product_type)?.fields) {
                updatedLineItems.push({ product_id: product.product_id, image_path: product.path, image_name: product.image_name, name: product.name, price_per_item: product.price, product_type: product.product_type, quantity: 1, stock: product.stock, total: product.price, store_id: product.store_id })
            }
            if (CardConfig?.cards_settings?.find(card => card?.product_type === product?.product_type)?.quantity) {
                updatedLineItems.push({ product_id: product.product_id, image_path: product.path, image_name: product.image_name, name: product.name, price_per_item: product.price, product_type: product.product_type, quantity: 1, stock: product.stock, total: product.price, store_id: product.store_id })
            }

            const remove_duplicate_products = Array.from(new Set(updatedLineItems?.map(JSON.stringify)))?.map(JSON.parse);
            const selected_products = [...new Map(remove_duplicate_products?.map(item => [item['product_id'], item])).values()];
            // setStock(product?.stock)
            setLineItems(selected_products)
            //clear the input value after select the products
            setProductSearchValue('')
            setMode('closeProductListDropdown')
            setSearchInProgress(false)
        }

    }

    //remove the line item
    const onClickRemoveLineItem = (lineItemIndex) => {
        var updatedLineItems = { ...lineItems }
        updatedLineItems = lineItems.filter((updated_item, i) => i != lineItemIndex)
        setLineItems(updatedLineItems)
    }

    //get the customer name 
    const onSearchCustomerList = (event) => {
        setCustomerSearchValue(event.target.value)
        if (event.target?.value?.length > 2) {
            getCustomerList(event.target.value)
            setCustomerLoader(true)
        }
    }

    const onClickCustomerList = () => {
        setMode('openCustomerDropdown')
    }

    //get all the customer data
    const getCustomerList = (searchValues) => {

        setMode('openCustomerDropdown')
        setSearchInProgress(true)
        //clear the recipients if the customer has been changed
        clearRecipientsForAllLineItems()
        var customer_initial_payload = {
            "store_id": store?.id,
            "status": "active",
            "page_size": 50,
            "current_page": 1
        }

        var customer_pay_load = searchValues
            ? ((customer_initial_payload.filters = {
                "conditions": [
                    { "field": "first_name", "operator": "like", "value": searchValues, "dataType": "string" },
                    { "field": "last_name", "operator": "like", "value": searchValues, "dataType": "string" },
                    { "field": "email", "operator": "like", "value": searchValues, "dataType": "string" }
                ],
                "logic": "1-or-2-or-3"
            }),
                customer_initial_payload)
            : customer_initial_payload;

        // console.log("customer_initial_payload",customer_initial_payload)
        callAPI('/admin/customers/by-store',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                },
                body: JSON.stringify(customer_pay_load)
            }, navigate, setLoading, setStore)
            .then(data => {
                if (data.success) {
                    setCustomerList(data.records)
                    setCustomerLoader(false)
                }
            })
            .finally(() => {
                setSearchInProgress(false)
            })
    }

    //get the selected customer
    const onClickSelectCustomer = (selectedCustomerId) => {
        // console.log("selectedCustomerId",selectedCustomerId)
        setSelectedCustomer(selectedCustomerId)
        //get recipient list based on the selected customer
        getRecipientList(selectedCustomerId)
        //clear the input value after select the customer
        setCustomerSearchValue('')
        setMode('closeCustomerDropdown')
    }

    // get recipient list based on the selected customer
    const getRecipientList = (selectedCustomerId) => {
        setSelectedCustomerId(selectedCustomerId)
    }

    useEffect(() => {
        if (selectedCustomerId != '' && selectedCustomer != '') {

            var customer_address_initial_payload = {
                'customer_id': selectedCustomerId || selectedCustomer,
                currentPage: 1
            }

            var customer_address_payload = customerAddressPayload || customer_address_initial_payload
            // console.log("customer_address_payload", customer_address_payload)
            callAPI(`/admin/address/list-by-customer`,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': user?.auth_token
                    },
                    body: JSON.stringify(customer_address_payload)
                }, navigate, setLoading)
                .then(data => {
                    if (data.success) {
                        if (data?.records) {
                            // console.log("data?.records",data?.records)
                            const recipients_list = Object.entries(data?.records)
                                .filter(([_, value]) => value !== null && value !== "null")
                                ?.map(([key, value]) => value);

                            const transformedArray = recipients_list?.map(obj => {
                                const newObj = {};
                                for (const key in obj) {
                                    if (obj[key] != null) {
                                        newObj[key] = obj[key];
                                    }
                                }
                                return newObj;
                            });
                            setRecipients(transformedArray)

                            // new added address 
                            const targetAddress = transformedArray?.find(address => address?.address_id == newRecipientAddress)
                            setNewRecipientAddress("")
                            if (targetAddress) {
                                const customerAddressId = targetAddress.customer_address_id;
                                onChangeRecipientCheckBox(
                                    { target: { checked: true } },
                                    customerAddressId
                                );

                                if (allrecipientsSelectedCheckBox) {
                                    setAllRecipientsSelectedCheckBox(true)
                                }

                            }
                        }
                        setResultInfo({
                            total_rows: data.total_rows,
                            fetched_rows: data.fetched_rows,
                            total_pages: data.total_pages,
                            page_size: data.page_size,
                            current_page: data.current_page,
                            success: data.success,
                            errors: data.errors
                        })
                    }
                })
        }

        if (mode == "" || mode == "closeRecipientList") {
            setSelectedRecipientAddressIds([])
        }
    }, [mode, selectedCustomerId, customerAddressPayload])

    const onPageSelect = (currentPage) => {
        onClickSearchBtn(currentPage)
    }
    //on enter search the store list
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            getSearchResult()
        }
    }

    //during on search icon clicked get the result of store
    const onClickSearchBtn = (currentPage) => {
        getSearchResult(currentPage)
    }

    const onChangeSearchValue = (event) => {
        // console.log("on search value - ", event.target.value)
        setSearchValue(event.target.value)
        var customer_address_initial_search_payload = {
            'customer_id': selectedCustomerId || selectedCustomer,
            "page_size": 10,
            "current_page": 1
        }

        var customer_address_search_updated_payload = event.target.value
            ? ((customer_address_initial_search_payload.filters = {
                "conditions": [
                    { "field": "ca.name", "operator": "like", "value": event.target.value, "dataType": "string" },
                    { "field": "line_1", "operator": "like", "value": event.target.value, "dataType": "string" },
                    { "field": "line_2", "operator": "like", "value": event.target.value, "dataType": "string" },
                    { "field": "city", "operator": "like", "value": event.target.value, "dataType": "string" },
                    { "field": "state", "operator": "like", "value": event.target.value, "dataType": "string" }
                ],
                "logic": "1-or-2-or-3-or-4-or-5"
            }),
                customer_address_initial_search_payload)
            : customer_address_initial_search_payload;

        // console.log("customer_address_search_updated_payload",customer_address_search_updated_payload)
        setCustomerAddressPayload(customer_address_search_updated_payload)
    }

    const getSearchResult = (currentPage) => {

        var search_value = searchValue || ""

        var customer_address_initial_page_payload = {
            'customer_id': selectedCustomerId || selectedCustomer,
            "page_size": 10,
            "current_page": currentPage
        }

        var customer_address_updated_payload = search_value
            ? ((customer_address_initial_page_payload.filters = {
                "conditions": [
                    { "field": "ca.first_name", "operator": "like", "value": search_value, "dataType": "string" },
                    { "field": "ca.last_name", "operator": "like", "value": search_value, "dataType": "string" },
                    { "field": "line_1", "operator": "like", "value": search_value, "dataType": "string" },
                    { "field": "line_2", "operator": "like", "value": search_value, "dataType": "string" },
                    { "field": "city", "operator": "like", "value": search_value, "dataType": "string" },
                    { "field": "state", "operator": "like", "value": search_value, "dataType": "string" }
                ],
                "logic": "1-or-2-or-3-or-4-or-5-or-6"
            }),
                customer_address_initial_page_payload)
            : customer_address_initial_page_payload;

        setCustomerAddressPayload(customer_address_updated_payload)
    }

    //refresh the recipient list when new recipient is created or edited
    useEffect(() => {
        getRecipientList(selectedCustomer)
        //apply css based on the orders
        navigate("", { state: { from: "orders" } })
    }, [refreshCount])

    //used for refresh the list
    const refreshTable = () => {
        setRefreshCount(refreshCount + 1)
    }

    //after completion of create or edit order set alert message and refersh the list
    const onCompletion = (alert, customer_address_id) => {
        setAlert({ text: alert.text, type: alert.type })
        if (alert.type == "success") {
            refreshTable()
            setNewRecipientAddress(customer_address_id)
        }
    }

    //get selected customer address
    useEffect(() => {
        if (selectedCustomer != '' && selectedCustomer) {
            // console.log("selectedCustomer", selectedCustomer)
            callAPI(`/admin/customers/${selectedCustomer}`,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': user?.auth_token
                    }
                }, navigate, setLoading, setStore)
                .then(data => {
                    if (data.success) {
                        setReturnAddress(data.record)
                        //store return address data to returnaddressFormvalues for getting new values when the data comes in modal
                        returnAddressFormvalues.line_1 = data.record?.line_1
                        returnAddressFormvalues.line_2 = data.record?.line_2 ? data.record?.line_2 : ""
                        returnAddressFormvalues.city = data.record?.city
                        returnAddressFormvalues.state = data.record?.state
                        returnAddressFormvalues.zip = data.record?.zip
                        returnAddressFormvalues.country = data.record?.country || "USA"
                        if (data?.record?.line_1) {
                            setIsSameAsReturnAddress(true)
                        }
                        setReturnAddressFormValues(returnAddressFormvalues)
                    }
                })
        }
    }, [selectedCustomer])

    //get country list
    useEffect(() => {
        callAPI("/admin/address/list",
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                },

            }, navigate, setLoading)
            .then(data => {
                if (data.success) {
                    SetCountryList(data?.records);
                }
            })
    }, [selectedCustomer])

    //set selected line item index when click the individual line item
    const onClickRecipients = (lineItemIndex) => {
        setSelectedLineItemIndex(lineItemIndex)
        setMode('openRecipientList')
        getSearchResult()
    }

    const onCloseRecipient = () => {
        setSearchValue("")
        setMode('closeRecipientList')
    }

    //get recipient id when onchange the recipient checkbox
    const onChangeRecipientCheckBox = (event, recipientId) => {
        var totalCost = ''
        var updatedItems = [...lineItems]
        if (event.target.checked) {
            //push the checked item to line items of reipients for selected line item
            updatedItems[selectedLineItemIndex].recipients.push(recipientId)
            //update the quanity based on the recipients length for selected line item
            updatedItems[selectedLineItemIndex].quantity = updatedItems[selectedLineItemIndex].recipients.length
            //calculate the total value for selected line item
            totalCost = updatedItems[selectedLineItemIndex].quantity * updatedItems[selectedLineItemIndex].price_per_item
            updatedItems[selectedLineItemIndex].total = totalCost
            setLineItems(updatedItems)

            if (selectedRecipientAddressIds?.length == 0) {
                setSelectedRecipientAddressIds([recipientId])
            }
            else if (selectedRecipientAddressIds?.length != 0) {
                setSelectedRecipientAddressIds((previousRecipientIds) => [...previousRecipientIds, recipientId])
            }
        }
        else {
            //remove the de-selected item from an line items 
            updatedItems[selectedLineItemIndex].recipients = lineItems[selectedLineItemIndex].recipients.filter((rec_id) => rec_id != recipientId)
            //update the quanity based on the recipients length for selected line item
            updatedItems[selectedLineItemIndex].quantity = updatedItems[selectedLineItemIndex].recipients.length
            //calculate the total value for selected line item
            totalCost = updatedItems[selectedLineItemIndex].quantity * updatedItems[selectedLineItemIndex].price_per_item
            updatedItems[selectedLineItemIndex].total = totalCost
            setLineItems(updatedItems)

            setSelectedRecipientAddressIds((previousRecipientIds) =>
                previousRecipientIds.filter((Recipient_address_id) => Recipient_address_id !== recipientId)
            );
        }

    }

    //remove the recipients from an line items
    const onClickRemoveRecipient = (lineItemIndex, recipientId) => {
        var totalCost = ''
        var updatedItems = [...lineItems]
        updatedItems[lineItemIndex].recipients = updatedItems[lineItemIndex].recipients.filter((recipient, i) => recipient != recipientId)
        //reduce quantity by 1
        updatedItems[lineItemIndex].quantity = updatedItems[selectedLineItemIndex].recipients.length
        //recalculate total
        totalCost = updatedItems[lineItemIndex].quantity * updatedItems[lineItemIndex].price_per_item
        updatedItems[selectedLineItemIndex].total = totalCost
        setLineItems(updatedItems)
        // setStock(stock + 1)
    }

    //clear the recipients if the customer has been changed
    const clearRecipientsForAllLineItems = () => {
        var updatedItems = [...lineItems]
        updatedItems?.map((updated_item, index) => {
            updated_item.recipients = []
            updated_item.quantity = 0
            updated_item.total = 0
        })
    }

    //get form values for payments
    const onChangePaymentInfo = (event) => {
        setPaymentInfo({ ...paymnetInfo, [event.target.name]: event.target.value })
        hideErrorMessage()
    }

    //open add new recipient popup 
    const onClickAddNewRecipient = () => {
        setMode('addNewRecipient')
    }

    //open edit recipient popup
    const onClickEditRecipient = (selectedAddressId) => {
        setMode('editRecipient')
        setSelectedRecipient(selectedAddressId)
        setEditRecipientAddress(editRecipientAddress + 1)
        setNewRecipientAddress("")
    }

    //calculate the totals when line items change
    useEffect(() => {
        calculateTotals()
    }, [lineItems, donationAmount, processingFeesCheckbox])

    const calculateTotals = () => {
        var subTotal = 0;
        lineItems?.map((lineItem) => (subTotal += parseFloat(lineItem?.total) || 0));

        var total = 0; // Initialize total to a default value

        if (subTotal > 0 && donationAmount) {
            total = parseFloat(subTotal) + parseFloat(donationAmount);
        }

        if (!donationAmount || parseFloat(donationAmount) === 0) {
            total = subTotal; // If donationAmount is not defined or is zero, use subTotal as the total
        }

        const feesPercentage = 4; // 4% donation
        const feesAmount = (Number(total) * feesPercentage) / 100

        if (processingFeesCheckbox) {

            total += feesAmount
        }

        if (processingFeesCheckbox) {
            setProcessingFeesAmount(feesAmount)
        }
        else {
            setProcessingFeesAmount(0)
        }

        setItemSummary({
            subTotal: parseFloat(subTotal).toFixed(2),
            tax: 0.00,
            total: parseFloat(total).toFixed(2),
        });

    }

    const verifyAddress = (address) => {

        if (address == "returnAddressFormvalues") {
            var address_validation = returnAddressFormvalues
        }

        if (address == "billingAddressFormValues") {
            var address_validation = billingAddressFormValues
        }

        if (address_validation) {
            callAPI(`/admin/address/validate`,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': user?.auth_token
                    },
                    body: JSON.stringify(address_validation)
                }, navigate, setLoading, setStore)
                .then(data => {
                    if (data.success) {
                        if (address == "billingAddressFormValues") {
                            setBillingAddress(billingAddressFormValues)
                        }
                        if (address == "returnAddressFormvalues") {
                            setReturnAddress({ ...returnAddress, ...returnAddressFormvalues })
                        }
                        setMode("closeBillingAddressModal")
                    }
                    else {
                        setErrors(data.errors)
                        // console.log("data.errors",data.errors)
                        setShowErrorMessage(true)
                    }
                })
        }
    }

    const saveReturnAddress = () => {
        verifyAddress("returnAddressFormvalues")
    }

    //set billing address and validate form fields
    const saveBillingAddress = () => {
        verifyAddress("billingAddressFormValues")
    }

    var selected_customer
    if (returnAddress?.['first_name'] == "NULL" && returnAddress?.['last_name'] == "NULL") {
        selected_customer = "invaild_customer"
    }
    else if (returnAddress?.['first_name'] && returnAddress?.['last_name'] ? returnAddress?.['first_name'] + " " + returnAddress?.['last_name'] : "") {
        selected_customer = returnAddress?.['first_name'] && returnAddress?.['last_name'] ? returnAddress?.['first_name'] + " " + returnAddress?.['last_name'] : ""
    }

    var required_fields = {
        product: lineItems?.length == 0 ? "product" : "",
        customer: selected_customer == '' ? "customer" : "",
        returnAddress: returnAddress?.line_1 && returnAddress?.city && returnAddress?.state && returnAddress?.country && returnAddress.zip ? "" : "return_address",
        billingAddress: billingAddress?.line_1 && billingAddress?.city && billingAddress?.state && billingAddress?.country && billingAddress.zip || isSameAsReturnAddress ? "" : "billing_address"
    };

    function checkRecipientsAvailability(product) {
        return product?.recipients?.length == 0;
    }

    useEffect(() => {

        const newZeroRecipientsIndices = [];
        var recipient_error = ""
        lineItems?.forEach((product, index) => {
            const isRecipientsAvailable = checkRecipientsAvailability(product);
            if (isRecipientsAvailable) {
                const recipientsLengthZero = product?.recipients?.length == 0;

                if (recipientsLengthZero) {
                    newZeroRecipientsIndices?.push(index); // Store the index with zero recipients
                }
            }
        });

        setZeroRecipientsIndices(newZeroRecipientsIndices);

        if (newZeroRecipientsIndices?.length != 0) {
            // There are items with zero recipients, set error accordingly
            setRecipientError("recipient");
            recipient_error = "recipient"
        } else {
            // No items with zero recipients, clear the error
            setRecipientError("");
            recipient_error = ""
        }

    }, [lineItems])


    //Save order
    const createOrder = (save) => {

        setSaveCardFromsData(savecardFromsData + 1)
        setOnOrderLoading(true)
        var billing_address;

        if (isSameAsReturnAddress) {
            if (isSameAsReturnAddress == true)
                billing_address = returnAddress
            else
                billing_address = ''
        }
        else {
            billing_address = billingAddress
        }

        paymnetInfo.payment_mode = paymnetInfo?.payment_mode || "PMNT-MD-STRIPE"

        if (paymnetInfo?.payment_mode == "PMNT-MD-STRIPE" && !paymnetInfo.reference && paymnetInfo?.payment_mode === "PMNT-MD-PAYPAL") {
            paymnetInfo.reference = null
        }

        var pay_load = {
            "store_id": parseInt(store?.id),
            "order_mode": String(save),
            "line_items": lineItems,
            "customer_id": selectedCustomer,
            "subtotal": itemSummary.subTotal,
            "additional_donation": donationAmount,
            "tax": itemSummary.tax,
            "total": itemSummary.total,
            "processing_fee": processingFeesAmount,
            "payment": paymnetInfo,
            "requested_send_date": send_dates.includes(requested_send_date) ? new Date(moment(requested_send_date, "MMM-DD-YYYY").format("YYYY-MM-DD")) : null
        }
        if (returnAddress.line_1 != null) {
            pay_load.return_address = returnAddress
        }
        if (billing_address.line_1 != null) {
            pay_load.billing_address = billing_address
        }

        // console.log("pay_load", pay_load)

        var productTypeFieldsError = "";  // Initialize the variable outside the loop

        lineItems?.forEach((lineItem) => {
            const productType = CardConfig?.cards_settings?.find(card => card?.product_type === lineItem?.product_type)?.fields;
            if (productType) {
                if (lineItem?.customization?.fields) {
                    var MessageError = localStorage.getItem('Message')
                    if (MessageError == "error") {
                        productTypeFieldsError = "error";
                    } else {
                        productTypeFieldsError = "";
                    }
                } else {
                    productTypeFieldsError = "error";
                }
            } else {
                productTypeFieldsError = "";
            }
        });

        // console.log("productTypeFieldsError",productTypeFieldsError)

        if (required_fields?.product == "" && required_fields?.customer == "" && required_fields?.returnAddress == "" && required_fields?.billingAddress == "" && recipientError == "" && productTypeFieldsError == "") {
            const inValidItems = lineItems?.filter(item => item?.quantity == 0);
            // console.log ("validItems",validItems)
            if (inValidItems?.length == 0) {
                callAPI('/admin/orders/create',
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'token': user?.auth_token
                        },
                        body: JSON.stringify(
                            pay_load
                        )
                    }, navigate, setLoading, setStore)
                    .then(data => {
                        if (data.success) {
                            if (save == "save-and-pay" && paymnetInfo?.payment_mode == "PMNT-MD-STRIPE") {
                                var url = data?.payment_url
                                window.open(url, '_self');
                            }

                            else if (save == "save-and-pay" && paymnetInfo?.payment_mode == "PMNT-MD-PAYPAL") {
                                var order_id = data?.order_id
                                navigate(`/admin/order/${order_id}/paypal`)
                                localStorage.setItem('order_id', order_id)
                            }
                            else {
                                var order_id = data?.order_id
                                navigate(`/admin/order/edit/${order_id}`)
                            }
                            localStorage.setItem('Message', order_page_message?.create_message)
                            setOnOrderLoading(false)
                        }
                        else {
                            setAlert({ text: item_required_error_message, type: alert_message_type?.error })
                            setOnOrderLoading(false)
                            setShowErrorMessage(true)
                            const return_address = data?.errors?.map(item => item?.message);
                            if (return_address?.[0] == "return_address_reqd") {
                                required_fields = { returnAddress: "return_address" }
                                setErrors(data.errors)
                            }
                            else {
                                setErrors(data.errors)
                            }
                            // console.log("data.errors", data.errors)
                        }
                    })
            } else {
                setShowErrorMessage(true)
                setOnOrderLoading(false)
                scrollToTop()
                setAlert({ text: "Product quantity is required", type: alert_message_type?.error })
            }
        }
        else {
            setShowErrorMessage(true)
            setOnOrderLoading(false)
            scrollToTop()
            setAlert({ text: item_required_error_message, type: alert_message_type?.error })
        }
    }

    const hideErrorMessage = (message) => {

        if (message == "closeReturnAddressModal") {
            setMode('closeReturnAddressModal')
        }

        if (message == "closeBillingAddressModal") {
            setMode('closeBillingAddressModal')
        }
        if (true) {
            setShowErrorMessage(false)
            required_fields = {}
        }
    }

    const numberInputOnWheelPreventChange = (e) => {
        // Prevent the input value change
        e.target.blur()

        // Prevent the page/container scrolling
        e.stopPropagation()

        // Refocus immediately, on the next tick (after the current function is done)
        setTimeout(() => {
            e.target.focus()
        }, 0)
    }

    const payment_option = Object.keys(order_page_labels?.add_order_and_edit_order?.payment_block?.payment_mode_option)?.map((name) => {
        const value = order_page_labels?.add_order_and_edit_order?.payment_block?.payment_mode_option[name];
        return `${name},${value}`;
    });

    const onSaveCardForm = (customizationFields, index) => {
        // console.log("customizationFields", customizationFields)
        var copy_of_line_item = [...lineItems]
        if (customizationFields) {
            copy_of_line_item[index] = { ...copy_of_line_item[index], customization: { fields: customizationFields } }
            setLineItems(copy_of_line_item);
            // console.log("Copy_of_line_item", copy_of_line_item)
        }
        setSaveCardFromsData(0)
    };

    const onHideUpload = (alertMessage) => {
        setMode("openRecipientList")
        setAlert(alertMessage)
    }

    const onCloseMessagePopUp = () => {
        setMode("openRecipientList")
    }

    const onDelete = () => {

        var pay_load = {
            customer_id: parseInt(selectedCustomer),
            customer_address_ids: selectedRecipientAddressIds
        }

        callAPI(`/admin/address/delete-recipients`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                },
                body: JSON.stringify(pay_load)
            }, navigate, setLoading)
            .then(data => {
                // console.log("admin users update api response " + JSON.stringify(data));
                if (data.success) {
                    setMode("openRecipientList")
                    var delete_message = selectedRecipientAddressIds?.length == 1 ? "Recipient Deleted successfully" : "Recipients Deleted successfully"
                    setAlert({ text: delete_message, type: "success" })

                    //    lineItems?.forEach(lineItem => {lineItem.recipients = lineItem?.recipients?.filter(recipientId => !selectedRecipientAddressIds.includes(recipientId))})
                    lineItems?.forEach(lineItem => {
                        if (lineItem?.recipients) {
                            const initialRecipientCount = lineItem.recipients.length;
                            lineItem.recipients = lineItem.recipients.filter(recipientId => !selectedRecipientAddressIds.includes(recipientId));

                            // Calculate the number of recipients removed
                            const removedRecipientCount = initialRecipientCount - lineItem.recipients.length;
                            const total = lineItem.price_per_item * removedRecipientCount
                            // Reduce quantity based on the number of recipients removed
                            lineItem.quantity -= removedRecipientCount;
                            lineItem.total -= total;
                        }
                    });

                } else {
                    setMode("openRecipientList")
                    setAlert({ text: "Error occured in recipient delete.", type: "error" })
                }
            }
            )
    }
    const onSelectRecipientAddressIds = (address_ids) => {
        setSelectedRecipientAddressIds(address_ids)
        setMode("OpenDeletePopUp")
    }

    const onCloseCustomerList = () => {
        setMode("")
        setCustomerSearchValue("")
    }

    const onCloseProductList = () => {
        setMode("")
        setProductSearchValue("")
    }

    // select all recipients
    useEffect(() => {
        if (selectedCustomerId != '' && selectedCustomer != '') {

            var customer_address_initial_payload = {
                'customer_id': selectedCustomerId || selectedCustomer,
                page_size: "all",
                currentPage: 1
            }

            var customer_address_payload = customer_address_initial_payload
            // console.log("customer_address_payload", customer_address_payload)
            callAPI(`/admin/address/list-by-customer`,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': user?.auth_token
                    },
                    body: JSON.stringify(customer_address_payload)
                }, navigate, setLoading)
                .then(data => {
                    if (data.success) {
                        if (data?.records) {
                            // console.log("data?.records",data?.records)
                            const recipients_list = Object.entries(data?.records)
                                .filter(([_, value]) => value != null && value != "null")
                                ?.map(([key, value]) => value);

                            const filteredRecipients = recipients_list?.map(originalObject => {
                                const filteredObject = {};
                                for (const key in originalObject) {
                                    if (originalObject[key] != null) {
                                        filteredObject[key] = originalObject[key];
                                    }
                                }
                                return filteredObject;
                            });

                            setAllRecipientAddresses(filteredRecipients)
                            if (lineItems?.[selectedLineItemIndex]?.recipients?.length) {
                                if (lineItems?.[selectedLineItemIndex]?.recipients.length == filteredRecipients?.length) {
                                    setAllRecipientsSelectedCheckBox(true)
                                } else {
                                    setAllRecipientsSelectedCheckBox(false)
                                }
                            } else {
                                setAllRecipientsSelectedCheckBox(false)
                            }
                        }
                    }
                }
                )
        }

        if (selectedCustomerId == '' && selectedCustomer == '') {
            setAllRecipientsSelectedCheckBox(false)
        }
    }, [selectedCustomerId, recipients])

    const onChangeSelecteAllRecipientAddressess = (event) => {
        const addressIds = allRecipientAddresses?.map(item => item?.customer_address_id);
        var totalCost = '';
        var updatedItems = [...lineItems];

        // Create a Set to store unique recipient addresses
        const uniqueRecipients = new Set(updatedItems[selectedLineItemIndex].recipients);

        if (event.target.checked) {
            // If the checkbox is checked, add all addresses to the Set
            addressIds?.forEach(addressId => uniqueRecipients?.add(addressId));
            setAllRecipientsSelectedCheckBox(true)
        } else {
            // If the checkbox is unchecked, remove the selected addresses from the Set
            addressIds?.forEach(addressId => uniqueRecipients?.delete(addressId));
            setAllRecipientsSelectedCheckBox(false)
        }

        // Convert the Set back to an array
        updatedItems[selectedLineItemIndex].recipients = Array.from(uniqueRecipients);

        // Update the quantity based on the length of recipients
        updatedItems[selectedLineItemIndex].quantity = updatedItems[selectedLineItemIndex].recipients.length;

        // Calculate the total value for the selected line item
        totalCost = updatedItems[selectedLineItemIndex].quantity * updatedItems[selectedLineItemIndex].price_per_item;
        updatedItems[selectedLineItemIndex].total = totalCost;

        // Update the state with the modified data
        setLineItems(updatedItems);

        // Update the SelectedRecipientAddressIds state with the correct values
        setSelectedRecipientAddressIds(updatedItems[selectedLineItemIndex].recipients);
    }


    // console.log("allRecipientAddress", allRecipientAddresses)
    // console.log("quantity",lineItems?.map(lineItem => lineItem?.quantity != 0))
    // console.log("selectedRecipientAddressIds", selectedRecipientAddressIds)
    // console.log("lineItems", lineItems)
    // console.log("recipients" , recipients)
    return (
        <React.Fragment>

            {alert?.text ?
                <div className="text-center">
                    <Alert text={alert.text} type={alert.type} setAlert={setAlert} />
                </div>
                : ''}

            <div className="saveButton">
                {onOrderLoading && <div className="position-relative">
                    <div className="nav-text  mb-3"><SecondaryLoader className={"order_loader_top"} /></div>
                </div>}
                {paymnetInfo.payment_mode != "PMNT-MD-PAYPAL" && <button className="btn btn-primary" disabled={payButton} onClick={() => createOrder("save-and-pay")}>{order_page_labels?.add_order_and_edit_order?.add_order_pay_stripe_btn}</button>}
                {paymnetInfo.payment_mode == "PMNT-MD-PAYPAL" && <button className="btn btn-primary" disabled={payButton} onClick={() => createOrder("save-and-pay")}>{order_page_labels?.add_order_and_edit_order?.add_order_pay_paypal_btn}</button>}
                <button className="btn btn-primary ml-3" onClick={() => createOrder("save")}>{order_page_labels?.add_order_and_edit_order?.create_btn}</button>
            </div>
            <div className="createOrderPage">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card card-default mt-5">
                            <div className="card-header card-header-border-bottom ">
                                <h2 className="mandatory_field mr-2">{order_page_labels?.add_order_and_edit_order?.customer_block?.title}</h2>
                                {customerLoader && <div className="col-lg-3 mb-3 position-relative">
                                    <div className="nav-text pl-3"><SecondaryLoader className={"customerLoader"} /></div>
                                </div>}
                                <Message type="errorMsg" text={errorMessages?.[required_fields?.customer]} showMessage={showErrorMessage} style={{ position: "absolute", right: "20px", top: "40px" }} />
                            </div>
                            <div className="card-body">
                                <div className="search-form d-lg-inline-block searchWrapper">
                                    <div className="input-group">
                                        <input type="text" id="search-input" className="form-control searchInput" value={customerSearchValue || ""} placeholder="Search by Customer First Name or Email" onChange={onSearchCustomerList} onClick={() => setMode('openCustomerDropdown')} />
                                        <span className="search_icon">
                                            <i className="mdi mdi-magnify"></i>
                                        </span>
                                    </div>
                                </div>
                                {/* customer dropdown */}
                                {mode == 'openCustomerDropdown' &&
                                    <div className="customerList" >
                                        {customerSearchValue?.length < 3 || !customerSearchValue ?
                                            <div style={{ height: "200px", textAlign: "center", paddingTop: "25px" }}>Please enter more than 2 characters</div> :
                                            <>
                                                <ul id="search-results">
                                                    {customerList?.map((list, index) => {
                                                        var selectedCustomerId = (selectedCustomer == list?.customer_id)

                                                        return (
                                                            <li key={index} className={selectedCustomerId ? "hasSelected" : ""} id={list?.customer_id} onClick={() => onClickSelectCustomer(list?.customer_id)}>
                                                                {list?.first_name == "NULL" ? "" : list?.first_name} {list?.last_name == "NULL" ? "" : list?.last_name}
                                                            </li>
                                                        )
                                                    })}
                                                    {customerList == '' && <span className="text-center mt-3 mb-5 message_center">{order_page_message?.customer_not_fount}</span>}
                                                </ul>
                                            </>
                                        }
                                        <div className="close_drop_down_button">
                                            <button className="btn btn-primary" onClick={() => onCloseCustomerList()}>Close</button>
                                        </div>
                                    </div>
                                }
                                <div className="selectedCustomer mt-3">
                                    {selectedCustomer ?
                                        <div className="row">
                                            <div className="col-lg-1.5 pr-0">
                                                <span className="nav-text text-dark mb-3">{order_page_labels?.add_order_and_edit_order?.customer_block?.title} : </span>
                                            </div>
                                            {selected_customer == "invaild_customer" ? <div className="col-lg-4 pl-1 error"> Invalid </div> :
                                                <div className="col-lg-4 position-relative">
                                                    {selected_customer ?
                                                        <div className="col-lg-12">
                                                            <span className="nav-text  mb-3 p-0">{selected_customer}</span>
                                                        </div>
                                                        :
                                                        <div className="col-lg-4 pl-1">
                                                            <div className="nav-text  mb-3"><SecondaryLoader className={"customerLoader"} /></div>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                        :
                                        ""}
                                </div>

                            </div>
                        </div>
                        <div className="card card-default mt-5" >
                            <div className="card-header card-header-border-bottom">
                                <h2 className="mandatory_field mr-2">{order_page_labels?.add_order_and_edit_order?.product_block?.title}</h2>
                                <Message type="errorMsg" text={errorMessages[required_fields?.product]} showMessage={showErrorMessage} style={{ position: "absolute", right: "20px", top: "40px" }} />
                                {productLoader && <div className="col-lg-3 mb-3 position-relative">
                                    <div className="nav-text pl-3"><SecondaryLoader className={"customerLoader"} /></div>
                                </div>}
                            </div>
                            <div className="card-body">
                                <div className="productSearchWrapper">
                                    <div className="search-form d-lg-inline-block searchWrapper">
                                        <div className="input-group">
                                            <input type="text" name="query" id="search-input" className="form-control searchInput" value={selectedCustomer == "" ? "" : productSearchValue || ""} placeholder="Search by Product Name" onChange={onSearchProducts} onClick={() => onClickProductList()} />
                                            <span className="search_icon">
                                                <i className="mdi mdi-magnify"></i>
                                            </span>
                                        </div>
                                    </div>
                                    {/* product list dropdown*/}
                                    {mode == 'openProductListDropdown' && <div>
                                        {mode == 'openProductListDropdown' && selectedCustomer != '' && selected_customer != "invaild_customer" ?
                                            <>
                                                {
                                                    mode == 'openProductListDropdown' &&
                                                    <div className="productList" >
                                                        <ul id="search-results">
                                                            {!searchInProgress ?
                                                                productList?.map((list, index) => {
                                                                    var alreadySelectedItem = lineItems?.find((item) => item.product_id == list.product_id)
                                                                    return (
                                                                        <li key={index} id={list?.product_id} className={`${alreadySelectedItem ? "hasSelected" : ""} `} onClick={() => onClickSelectProduct(list?.stock == 0 ? "" : list)}>
                                                                            Name : {list?.name}  | Type : {product_page_labels?.product_type?.[list?.product_type]}
                                                                        </li>
                                                                    )
                                                                })
                                                                :
                                                                <p className="text-center mt-5 mb-5">{order_page_message?.searching}</p>
                                                            }
                                                            {productList == '' && searchInProgress == false ? <p className="text-center mt-3 mb-5 ">{order_page_message?.product_not_fount}</p> : ""}
                                                        </ul>
                                                        <div className="close_drop_down_button">
                                                            <button className="btn btn-primary" onClick={() => onCloseProductList()}>Close</button>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                            :
                                            <div className="recipientModalContent messageContent">
                                                <div className="closeRecipientModal text-right" onClick={() => onCloseRecipient()}><span className="mdi mdi-close pr-2" role="button"></span></div>
                                                <div className="messageBody">
                                                    {order_page_message?.select_customer}
                                                </div>
                                            </div>
                                        }
                                    </div>}
                                </div>
                                {/* line items */}
                                {lineItems?.length != 0 ? <> {lineItems != null &&
                                    <div className="table-responsive addedOrderTable">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className="col-lg-1">{order_page_labels?.add_order_and_edit_order?.lineItems_tableHeaders?.image}</th>
                                                    <th className="col-lg-2">{order_page_labels?.add_order_and_edit_order?.lineItems_tableHeaders?.product}</th>
                                                    <th className="col-lg-3">{order_page_labels?.add_order_and_edit_order?.lineItems_tableHeaders?.recipient}</th>
                                                    <th className="col-lg-3">{order_page_labels?.add_order_and_edit_order?.lineItems_tableHeaders?.personalization}</th>
                                                    <th className="col-lg-1">{order_page_labels?.add_order_and_edit_order?.lineItems_tableHeaders?.price}</th>
                                                    <th className="col-lg-2">{order_page_labels?.add_order_and_edit_order?.lineItems_tableHeaders?.quantity}</th>
                                                    <th className="col-lg-1">{order_page_labels?.add_order_and_edit_order?.lineItems_tableHeaders?.total}</th>
                                                    <th className="col-lg-1">{order_page_labels?.add_order_and_edit_order?.lineItems_tableHeaders?.action}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {lineItems?.map((lineItem, lineItemIndex) => {
                                                    var recipientsLength = lineItem?.recipients?.length
                                                    var cards_settings_basedon_product_type = CardConfig?.cards_settings?.find(card => card?.product_type === lineItem?.product_type)
                                                    return (
                                                        <>
                                                            {cards_settings_basedon_product_type?.getRecipients &&
                                                                <tr key={lineItemIndex} style={{ position: "relative" }}>

                                                                    <td className="lineItemImg" colSpan={1}><img src={lineItem.image_path} alt={lineItem.image_name} /></td>
                                                                    <td className="col-lg-2">{lineItem.name}</td>
                                                                    <td className="addRecipient" colSpan="2">
                                                                        {recipientsLength == 0 &&
                                                                            <div onClick={() => onClickRecipients(lineItemIndex)} className="addRecipientsItem">
                                                                                <span className="mdi mdi-plus pr-2"></span>
                                                                                {order_page_labels?.add_order_and_edit_order?.add_recipient_btn}
                                                                            </div>
                                                                        }

                                                                        {lineItem?.recipients?.map((recipient_id, recipientIndex) => {
                                                                            var recipient = allRecipientAddresses?.find((recipient) => recipient.customer_address_id == recipient_id)
                                                                            // console.log("recipient", recipient)
                                                                            return (
                                                                                <div className={recipientIndex == recipientsLength - 1 && "newRecipient mb-1"}>
                                                                                    <>
                                                                                        <div className={`${recipient?.customer_address_id == undefined ? "hideRecipientWrapper" : "recipientWrapper"} addressAndPersonalizationMessageWrapper col-lg-12`}>
                                                                                            <div className="col-lg-4">
                                                                                                <span>
                                                                                                    {recipient?.salutation == "NULL" || recipient?.salutation == null || recipient?.salutation == "null" || recipient?.salutation == 0 || recipient?.salutation == "0" || !recipient?.salutation ? "" : recipient?.salutation} {recipient?.name == "NULL" ? "" : recipient?.name} <br />
                                                                                                    <page className={`${recipient?.line_3 ? "display_line_2" : ""}`}>{recipient?.line_3 == "NULL" || recipient?.line_3 == null || recipient?.line_3 == "null" || recipient?.line_3 == 0 || recipient?.line_3 == "0" || !recipient?.line_3 ? "" : recipient?.line_3} </page>
                                                                                                    {recipient?.line_1 == "NULL" ? "" : recipient?.line_1} <br />
                                                                                                    <p className={`${recipient?.line_2 ? "display_line_2" : ""}`}>{recipient?.line_2 == "NULL" || recipient?.line_2 == null || recipient?.line_2 == "null" || recipient?.line_2 == 0 || recipient?.line_2 == "0" || !recipient?.line_2 ? "" : recipient?.line_2} </p>
                                                                                                    {recipient?.city == "NULL" ? "" : recipient?.city}<br />
                                                                                                    {recipient?.state == "NULL" ? "" : recipient?.state} - {recipient?.zip == "NULL" ? "" : recipient?.zip}<br />
                                                                                                    {recipient?.country == "NULL" ? "" : countries[recipient?.country]}
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className="col-lg-2 ml-2 mr-2">
                                                                                                {recipient?.customer_address_id != undefined &&
                                                                                                    <span className="mdi mdi-close" onClick={() => onClickRemoveRecipient(lineItemIndex, recipient?.customer_address_id)}>
                                                                                                    </span>
                                                                                                }
                                                                                            </div>
                                                                                            <div key={recipientIndex} className="col-lg-5" style={{fontSize:"12px"}}>
                                                                                                {/* <div className="col-lg-5"> */}
                                                                                                {recipient.personalisation == "null" && recipient.personalisation == "NULL" ?
                                                                                                    '' : recipient.personalisation
                                                                                                }
                                                                                                <div className="pt-2"> <span className="fw-bold">Sender's name on card : </span><span>{recipient.sender_name_on_card}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            {/* <div>{recipient?.personalisation == "NULL" ? "" : recipient?.personalisation}</div> */}
                                                                                            {/* </div> */}
                                                                                        </div>
                                                                                    </>
                                                                                    <>
                                                                                        {recipientIndex == recipientsLength - 1 &&
                                                                                            <div style={{color:'var(--admin-color)'}} onClick={() => onClickRecipients(lineItemIndex)}>
                                                                                                <span className="mdi mdi-plus pr-2"></span>{order_page_labels?.add_order_and_edit_order?.add_recipient_btn}
                                                                                            </div>
                                                                                        }
                                                                                    </>
                                                                                </div>
                                                                            )
                                                                        })
                                                                        }
                                                                    </td>

                                                                    <td colSpan={1}>{parseFloat(lineItem.price_per_item).toFixed(2)}</td>
                                                                    <td colSpan={1}>{lineItem.quantity}</td>
                                                                    <td colSpan={1}>{parseFloat(lineItem.total).toFixed(2)}</td>
                                                                    <td className="removeProduct" onClick={() => onClickRemoveLineItem(lineItemIndex)}><span className="mdi mdi-delete"></span></td>

                                                                    {/* recipient list popup*/}
                                                                    {mode == 'openRecipientList' &&
                                                                        <td className="recipientModal">
                                                                            {selectedCustomer != '' ?
                                                                                <div className="recipientModalContent">
                                                                                    <div className="recipientModalBody">
                                                                                        <div className="card card-default">
                                                                                            <div className='card-body'>
                                                                                                <div className="recipientModalHeader">
                                                                                                    <div className="row justify-content-between">
                                                                                                        <div className="col-lg-9">
                                                                                                            <div className="row justify-content-between">
                                                                                                                <div className="col-lg-4">
                                                                                                                    <h5 className="text-dark">{order_page_labels?.add_order_and_edit_order?.recipients_block?.title}</h5>
                                                                                                                </div>
                                                                                                                <div className="col-lg-8">
                                                                                                                    <div className="alert mb-1 text-center">
                                                                                                                        <Alert text={alert?.text} type={alert?.type} setAlert={setAlert} className={{ order: "alertForOrder" }} style={{ width: "100%", right: "160%", top: "25px" }} />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-lg-4 ml-auto">
                                                                                                            <div className="d-flex justify-content-end">
                                                                                                                <div className="col-lg-4 stock_display">
                                                                                                                    {/* {order_page_labels?.add_order_and_edit_order?.recipients_block?.stock} : <span className={`${stock == 0 ? "out_of_stock" : ""} ml - 1`}> {stock} </span> */}
                                                                                                                </div>

                                                                                                                <div className="col-lg-6">
                                                                                                                    <button className="btn delectBtn position-relative mb-1" disabled={lineItems?.[selectedLineItemIndex]?.recipients?.length ? false : true} onClick={() => onSelectRecipientAddressIds(lineItems[selectedLineItemIndex]?.recipients)}>{order_page_labels?.add_order_and_edit_order?.delete_btn}</button>
                                                                                                                </div>
                                                                                                                <div className="col-lg-6">
                                                                                                                    <button className="btn delectBtn   position-relative mb-1" onClick={() => setMode("OpenFileUpload")}>{order_page_labels?.add_order_and_edit_order?.upload_btn}</button>
                                                                                                                </div>
                                                                                                                <div className="col-lg-5">
                                                                                                                    <button className="btn btn-primary position-relative mb-1" onClick={onClickAddNewRecipient}>{order_page_labels?.add_order_and_edit_order?.add_recipient_btn}</button>
                                                                                                                </div>
                                                                                                                <div className="col-lg-1">
                                                                                                                    <div className="closeRecipientModal text-right" onClick={() => onCloseRecipient()}><span className="mdi mdi-close pr-2" role="button"></span></div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                                {recipients?.length == 0 ?
                                                                                                    <div className="row">
                                                                                                        <div className="col-12">
                                                                                                            <div className="card card-default ">
                                                                                                                <div className={`card - body m - auto no_recipient`}>
                                                                                                                    <p className="text-center">{order_page_message?.recipient_not_fount}</p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <>
                                                                                                        <div>
                                                                                                            <div className="search-form d-lg-inline-block searchWrapper ">
                                                                                                                <div className="input-group ">
                                                                                                                    <input type="text" className="form-control searchInput"
                                                                                                                        placeholder="Search by Recipient" value={searchValue} onChange={onChangeSearchValue} onKeyDown={handleKeyDown} />
                                                                                                                    <span className="search_icon">
                                                                                                                        <i className="mdi mdi-magnify"></i>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="table-responsive recipientTable">
                                                                                                            {!loading && recipients?.length == 0 &&
                                                                                                                <div className="row">
                                                                                                                    <div className="col-12">
                                                                                                                        <div className="card card-default ">
                                                                                                                            <div className={`card - body m - auto no_recipient`}>
                                                                                                                                <p className="text-center">{order_page_message?.recipient_not_fount}</p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>}

                                                                                                            {loading && <Loader className={"table_loader"} />
                                                                                                            }

                                                                                                            {!loading && recipients?.length != 0 ?
                                                                                                                <table className="table">
                                                                                                                    <thead>
                                                                                                                        <tr>
                                                                                                                            <th className="col-lg-1 ml-0 pl-1">
                                                                                                                                <div className="d-flex align-items-center">
                                                                                                                                    <input
                                                                                                                                        type="checkbox"
                                                                                                                                        role="button"
                                                                                                                                        className="m-0 p-0"
                                                                                                                                        onChange={onChangeSelecteAllRecipientAddressess}
                                                                                                                                        checked={allrecipientsSelectedCheckBox}
                                                                                                                                    />
                                                                                                                                    <span className="pl-2">All</span>
                                                                                                                                </div>

                                                                                                                            </th>
                                                                                                                            <th className="col-lg-3">{order_page_labels?.add_order_and_edit_order?.recipients_block?.table_header?.name}</th>
                                                                                                                            <th className="col-lg-3">{order_page_labels?.add_order_and_edit_order?.recipients_block?.table_header?.company}</th>
                                                                                                                            <th className="col-lg-3">{order_page_labels?.add_order_and_edit_order?.recipients_block?.table_header?.address}</th>
                                                                                                                            <th className="col-lg-2">{order_page_labels?.add_order_and_edit_order?.recipients_block?.table_header?.city}</th>
                                                                                                                            <th className="col-lg-2">{order_page_labels?.add_order_and_edit_order?.recipients_block?.table_header?.state}</th>
                                                                                                                            <th className="col-lg-2">{order_page_labels?.add_order_and_edit_order?.recipients_block?.table_header?.zip}</th>
                                                                                                                            <th className="col-lg-2">{order_page_labels?.add_order_and_edit_order?.recipients_block?.table_header?.country}</th>
                                                                                                                            <th className="col-lg-3">{order_page_labels?.add_order_and_edit_order?.recipients_block?.table_header?.personalization}</th>
                                                                                                                            <th className="col-lg-1">{order_page_labels?.add_order_and_edit_order?.recipients_block?.table_header?.action}</th>
                                                                                                                        </tr>
                                                                                                                    </thead>
                                                                                                                    <tbody >
                                                                                                                        {recipients?.map((recipient, index) => {
                                                                                                                            var isAlreadySelected = lineItems[selectedLineItemIndex]?.recipients?.some((selectedRecipients) => selectedRecipients == recipient?.customer_address_id)
                                                                                                                            return (

                                                                                                                                <tr key={index} className={isAlreadySelected ? "hasSelected" : ""} onClick={() => {
                                                                                                                                    if (newRecipientAddress == "") {
                                                                                                                                        onChangeRecipientCheckBox(
                                                                                                                                            { target: { checked: !isAlreadySelected } },
                                                                                                                                            recipient.customer_address_id
                                                                                                                                        );
                                                                                                                                    }
                                                                                                                                }} role="button">
                                                                                                                                    <td className="addressCheckBox">
                                                                                                                                        <input
                                                                                                                                            type="checkbox"
                                                                                                                                            role="button"
                                                                                                                                            id={recipient.customer_address_id}
                                                                                                                                            name={recipient.customer_address_id}
                                                                                                                                            value={recipient.customer_address_id}
                                                                                                                                            checked={isAlreadySelected}
                                                                                                                                        />
                                                                                                                                        <label htmlFor={recipient.customer_address_id} className="checkBoxLabel">

                                                                                                                                        </label>
                                                                                                                                    </td>
                                                                                                                                    <td>{recipient?.salutation == "NULL" ? "" : recipient?.salutation} {recipient?.name == "NULL" ? "" : recipient?.name} </td>
                                                                                                                                    <td>{recipient?.line_3 == "NULL" ? "" : recipient?.line_3} </td>
                                                                                                                                    <td>{recipient?.line_1 == "NULL" ? "" : recipient?.line_1 + " "} {recipient?.line_2 == "NULL" ? "" : recipient?.line_2}</td>
                                                                                                                                    <td>{recipient?.city == "NULL" ? "" : recipient?.city} </td>
                                                                                                                                    <td>{recipient?.state == "NULL" ? "" : recipient?.state}</td>
                                                                                                                                    <td>{recipient?.zip == "NULL" ? "" : recipient?.zip}</td>
                                                                                                                                    <td>{recipient?.country ? countries[recipient?.country] : ""}</td>
                                                                                                                                    <td>{recipient?.personalisation == "NULL" ? "" : recipient?.personalisation}</td>
                                                                                                                                    <td >
                                                                                                                                        <span className="mdi mdi-account-edit icon" onClick={() => onClickEditRecipient(recipient?.address_id)} ></span>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            )
                                                                                                                        })}
                                                                                                                    </tbody>
                                                                                                                </table>
                                                                                                                : ""
                                                                                                            }
                                                                                                        </div>
                                                                                                    </>
                                                                                                }
                                                                                                <div className="paginationWrapper">
                                                                                                    <Pagination totalRows={resultInfo.total_rows} fetchedRows={resultInfo.fetched_rows} totalPages={resultInfo.total_pages} pageSize={resultInfo.page_size} currentPage={resultInfo.current_page} onPageSelect={onPageSelect} label='users' loader={loading} />
                                                                                                </div>

                                                                                            </div>
                                                                                            <div className="bottomWrapper">
                                                                                                <div className="text-center recipientsCount">
                                                                                                    <div className="text-center">{order_page_labels?.add_order_and_edit_order?.recipients_block?.selected_recipients}: {lineItems?.[selectedLineItemIndex]?.recipients?.length}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="saveAddressBtn text-right" onClick={() => onCloseRecipient()}>
                                                                                                <button className="btn btn-primary" onClick={() => onCloseRecipient()}>{order_page_labels?.add_order_and_edit_order?.recipients_block?.done_btn}</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <div className="recipientModalContent messageContent">
                                                                                    <div className="closeRecipientModal text-right" onClick={() => onCloseRecipient()}><span className="mdi mdi-close pr-2" role="button"></span></div>
                                                                                    <div className="messageBody">
                                                                                        {order_page_message?.select_customer}
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </td>
                                                                    }
                                                                    {/* create recipient popup */}
                                                                    {mode == 'addNewRecipient' &&
                                                                        <td className="recipientModal">
                                                                            <div className="recipientModalContentWrapper">
                                                                                <div className="recipientModalBody">
                                                                                    <AddAddress title="Add Recipient" size="small" setMode={setMode} customerId={selectedCustomer} onCompletion={onCompletion} alertStyle={{ width: "100%" }} />
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    }

                                                                    {mode == 'OpenFileUpload' &&
                                                                        <td className="recipientModal recipientsUploads">
                                                                            <div className="recipientModalContentWrapper">
                                                                                <div className="recipientModalBody">
                                                                                    <FileUpload onHideUpload={onHideUpload} alertStyle={{ width: "fit-content" }} id={selectedCustomer} />
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    }

                                                                    {mode == 'OpenDeletePopUp' &&
                                                                        <td className="recipientModal">
                                                                            <div className="recipientModalContentWrapper">
                                                                                <div className="recipientModalBody">
                                                                                    <PopUpMessage delectMessage={true} isDelect={true} onDelete={onDelete} onClose={onCloseMessagePopUp} newMessage={"Are you sure you want to delete the selected recipients?"} back={"Recipients"} recipientsCount={lineItems?.[selectedLineItemIndex]?.recipients?.length} />
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                    }
                                                                    {/* edit recipient popup */}
                                                                    {mode == 'editRecipient' &&
                                                                        <td className="recipientModal">
                                                                            <div className="recipientModalContentWrapper">
                                                                                <div className="recipientModalBody">
                                                                                    <EditAddress title="Edit Recipient" size="small" setMode={setMode} customerId={selectedCustomer} selectedAddressId={selectedRecipient} onCompletion={onCompletion} />
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    }
                                                                    {zeroRecipientsIndices?.includes(lineItemIndex) &&
                                                                        <div className="mt-2 text-center">
                                                                            <Message type="errorMsg" text={errorMessages[recipientError]} showMessage={showErrorMessage} style={{ position: "absolute", top: "50%", left: "27%" }} />
                                                                        </div>
                                                                    }
                                                                </tr>
                                                            }
                                                            {cards_settings_basedon_product_type?.fields &&
                                                                <tr key={lineItemIndex}>
                                                                    <td className="lineItemImg"><img src={lineItem.image_path} alt={lineItem.image_name} /></td>
                                                                    <td className="col-lg-3">{lineItem.name}</td>
                                                                    <td className="addRecipient">
                                                                        <CardForm fields={cards_settings_basedon_product_type?.fields} onSaveCardForm={onSaveCardForm} lineitems={lineItems} onSaveOrder={savecardFromsData} lineItemIndex={lineItemIndex} />
                                                                    </td>
                                                                    <td className="personalization"></td>
                                                                    <td className="col-lg-2">{parseFloat(lineItem.price_per_item).toFixed(2)}</td>
                                                                    <td>{lineItem.quantity}</td>
                                                                    <td>{parseFloat(lineItem.total).toFixed(2)}</td>
                                                                    <td className="removeProduct" onClick={() => onClickRemoveLineItem(lineItemIndex)}><span className="mdi mdi-delete"></span></td>
                                                                </tr>
                                                            }

                                                            {cards_settings_basedon_product_type?.quantity &&
                                                                <tr key={lineItemIndex}>
                                                                    <td className="lineItemImg"><img src={lineItem.image_path} alt={lineItem.image_name} /></td>
                                                                    <td className="col-lg-3">{lineItem.name}</td>
                                                                    <td className="addRecipient"></td>
                                                                    <td className="personalization"></td>
                                                                    <td className="col-lg-2">{parseFloat(lineItem.price_per_item).toFixed(2)}</td>
                                                                    <td className="countWrapper col-lg-2">
                                                                        <span className="minusCount" onClick={() => modifyQuantity('subtraction', lineItemIndex, lineItem.stock - lineItem.quantity)} ><span>-</span></span>
                                                                        <input type="number " disabled name={lineItem.product_id} value={lineItem.quantity} className="quantityInput disabled_input p-0"></input>
                                                                        <span className="plusCount" onClick={() => modifyQuantity('addition', lineItemIndex, lineItem.stock - lineItem.quantity)}><span>+</span></span>
                                                                    </td>
                                                                    <td>{parseFloat(lineItem.total).toFixed(2)}</td>
                                                                    <td className="removeProduct" onClick={() => onClickRemoveLineItem(lineItemIndex)}><span className="mdi mdi-delete"></span></td>
                                                                </tr>
                                                            }

                                                        </>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                }
                                </> : ""
                                }
                            </div>
                        </div>

                        <div className="card card-default mt-5">
                            <div className="card-body card-header-border-bottom">
                                <div className="list-unstyled mt-3">
                                    <div className="row justify-content-between">
                                        <div className="donation_box">
                                            <div>{order_page_message?.donation_amount} </div>
                                            <input
                                                type="number"
                                                name="donation"
                                                className="form-control no-scroll"
                                                onKeyPress={(e) => {
                                                    const disallowedCharacters = ["-", "+"];
                                                    const inputValue = e.target.value;

                                                    if (disallowedCharacters.includes(e.key)) {
                                                        e.preventDefault();
                                                    }
                                                    if (inputValue.includes(".") && inputValue.split(".")[1].length >= 2) {
                                                        e.preventDefault();
                                                    }
                                                }}

                                                onKeyDown={(e) => {
                                                    // Prevent up and down arrow key actions
                                                    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                onChange={onchangeDonationAmount}
                                                onWheel={numberInputOnWheelPreventChange}
                                            />
                                        </div>

                                        <div className="donation_checkbox">
                                            <input type="checkbox" name="donation_checkbox" onChange={onChangeProcessingFeesCheckbox} />
                                            {/* <div>{order_page_message?.donation_checkbox} {store?.name}</div> */}
                                            <div>{order_page_message?.donation_checkbox}</div>
                                        </div>
                                        <div className="donation_box">
                                            <div>{order_page_message?.requested_send_date} </div>
                                            <select
                                                type="number"
                                                name="donation"
                                                className="form-control w-25 mx-4 "
                                                onKeyDown={(e) => {
                                                    // Prevent up and down arrow key actions
                                                    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                value={requested_send_date ?? "Send in next mail cycle "}
                                                onChange={(e) => setRequestedSendDate(e.target.value)}
                                            >
                                                <option>Send in next mail cycle </option>
                                                {send_dates.map((date, index) => {
                                                    return (
                                                        <option key={index} >
                                                            {date}
                                                        </option>
                                                    )
                                                })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card card-default mt-5">
                            <div className="card-body card-header-border-bottom">
                                <div className="list-unstyled mt-3">
                                    <div className="row justify-content-between">
                                        <div className="col-lg-10">
                                            <div className="mid pb-3 text-dark">{order_page_labels?.add_order_and_edit_order?.sub_total_block?.subtotal}</div>
                                        </div>
                                        <div className="col-lg-2 text-right">
                                            <div className="d-inline-block float-right text-default">{itemSummary?.subTotal || 0.00}</div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-between">
                                        <div className="col-lg-10">
                                            <div className="mid pb-3 text-dark">{order_page_labels?.add_order_and_edit_order?.sub_total_block?.donation}</div>
                                        </div>
                                        <div className="col-lg-2 text-right">
                                            <div className="d-inline-block float-right text-default">
                                                {isNaN(parseFloat(donationAmount)) ? '0.00' : parseFloat(donationAmount).toFixed(2)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-between">
                                        <div className="col-lg-10">
                                            <div className="mid pb-3 text-dark">{order_page_labels?.add_order_and_edit_order?.sub_total_block?.processing_fees}</div>
                                        </div>
                                        <div className="col-lg-2 text-right">
                                            <div className="d-inline-block float-right text-default">
                                                {isNaN(parseFloat(processingFeesAmount)) ? '0.00' : parseFloat(processingFeesAmount).toFixed(2)}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                        <div className="col-lg-10">
                                            <div className="mid pb-3 text-dark">{order_page_labels?.add_order_and_edit_order?.sub_total_block?.tax}</div>
                                        </div>
                                        <div className="col-lg-2 text-right">
                                            <div className="d-inline-block float-right text-default">{itemSummary?.tax || 0.00}</div>
                                        </div>
                                    </div> */}
                                    <hr className="w-100"></hr>
                                    <div className="row">
                                        <div className="col-lg-10">
                                            <div className="mid pb-3 text-dark">{order_page_labels?.add_order_and_edit_order?.sub_total_block?.total}</div>
                                        </div>
                                        <div className="col-lg-2 text-right">
                                            <div className="d-inline-block float-right text-default">{itemSummary?.total || 0.00}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card card-default mt-5">
                            <div className="card-header card-heade(r-border-bottom">
                                <h2 className="mandatory_field">{order_page_labels?.add_order_and_edit_order?.return_address?.title}</h2>
                                <Message type="errorMsg" text={errorMessages?.[required_fields?.returnAddress]} showMessage={showErrorMessage} style={{ position: "absolute", right: "20px", top: "40px" }} />
                            </div>
                            <div className="card-body card-header-border-bottom">
                                {selectedCustomer != '' ?
                                    returnAddress.line_1 != null ?
                                        <div className="row justify-content-between">
                                            <div className="col-lg-9">
                                                <div className="customerAddress">
                                                    <div className="addressList">

                                                        {returnAddress?.line_1 == "NULL" ? "" : returnAddress?.line_1}<br />
                                                        <span className={`${returnAddress?.line_2 ? "display_line_2" : ""} `}>{returnAddress?.line_2 == "NULL" ? "" : returnAddress?.line_2} </span>
                                                        {returnAddress.city == "NULL" ? "" : returnAddress?.city}<br />
                                                        {returnAddress.state == "NULL" ? "" : returnAddress?.state} - {returnAddress.zip == "NULL" ? "" : returnAddress?.zip}<br />
                                                        {returnAddress.country == "NULL" ? "" : countries[returnAddress.country]}

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 text-right">
                                                <span className="mdi mdi-account-edit icon" onClick={() => setMode('openReturnAddressModal')}></span>
                                            </div>
                                        </div>
                                        :
                                        <div className="returnAddress" onClick={() => setMode("openReturnAddressModal")}><span className="mdi mdi-plus pr-2"></span>{order_page_labels?.add_order_and_edit_order?.return_address?.add_btn}</div>
                                    :
                                    <div className="returnAddress" onClick={() => setMode("openReturnAddressModal")}><span className="mdi mdi-plus pr-2"></span>{order_page_labels?.add_order_and_edit_order?.return_address?.add_btn}</div>


                                }
                            </div>
                            {mode == 'openReturnAddressModal' &&
                                <div className="addressModal">
                                    <div className="addressModalContent">
                                        <div className="addressModalHeader">
                                            <div className="row justify-content-between">
                                                <div className="col-lg-6">
                                                    <h5 className="text-dark">{order_page_labels?.add_order_and_edit_order?.return_address?.title}</h5>
                                                </div>
                                                <div className="col-lg-6 text-right">
                                                    <div className="closeRecipientModal text-right" onClick={() => hideErrorMessage("closeReturnAddressModal")}><span className="mdi mdi-close pr-2" role="buttton"></span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="addressModalBody">
                                            <div className="row mt-4">
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="form-label mandatory_field">{order_page_labels?.add_order_and_edit_order?.return_address?.line_1}</label>
                                                        <input type="text" className="form-control" name='line_1' value={returnAddressFormvalues['line_1'] == "NULL" ? "" : returnAddressFormvalues?.line_1 || ""} maxLength="200" onChange={onChangeReturnAddressFormValues} />
                                                        <div className="errorMessage">
                                                            <Message type="errorMsg" text={errorMessages[ErrorMessage({ fieldName: 'line_1', errors })] || ErrorMessage({ fieldName: 'line_1', errors })} showMessage={showErrorMessage} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="form-label">{order_page_labels?.add_order_and_edit_order?.return_address?.line_2}</label>
                                                        <input type="text" className="form-control" name='line_2' value={returnAddressFormvalues['line_2'] == "NULL" ? "" : returnAddressFormvalues?.line_2 || ""} maxLength="200" onChange={onChangeReturnAddressFormValues} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="form-label mandatory_field">{order_page_labels?.add_order_and_edit_order?.return_address?.city}</label>
                                                        <input type="text" className="form-control" name='city' value={returnAddressFormvalues['city'] == "NULL" ? "" : returnAddressFormvalues?.city || ""} maxLength="30" onChange={onChangeReturnAddressFormValues} />
                                                        <div className="errorMessage">
                                                            <Message type="errorMsg" text={errorMessages[ErrorMessage({ fieldName: 'city', errors })] || ErrorMessage({ fieldName: 'city', errors })} showMessage={showErrorMessage} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="form-label mandatory_field">{order_page_labels?.add_order_and_edit_order?.return_address?.state}</label>
                                                        <input type="text" className="form-control" name='state' value={returnAddressFormvalues['state'] == "NULL" ? "" : returnAddressFormvalues?.state || ""} maxLength="30" onChange={onChangeReturnAddressFormValues} />
                                                        <div className="errorMessage">
                                                            <Message type="errorMsg" text={errorMessages[ErrorMessage({ fieldName: 'state', errors })] || ErrorMessage({ fieldName: 'state', errors })} showMessage={showErrorMessage} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="form-label mandatory_field">{order_page_labels?.add_order_and_edit_order?.return_address?.zip}</label>
                                                        <input type="text" className="form-control" name='zip' value={returnAddressFormvalues['zip'] == "NULL" ? "" : returnAddressFormvalues?.zip || ""} maxLength="20" onChange={onChangeReturnAddressFormValues} />
                                                        <div className="errorMessage">
                                                            <Message type="errorMsg" text={errorMessages[ErrorMessage({ fieldName: 'zip', errors })] || ErrorMessage({ fieldName: 'zip', errors })} showMessage={showErrorMessage} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="form-label mandatory_field">{order_page_labels?.add_order_and_edit_order?.return_address?.country}</label>
                                                        <select className="form-control overflow-auto" name='country' value={returnAddressFormvalues['country'] || ""} onChange={onChangeReturnAddressFormValues}>
                                                            <option hidden>{order_page_labels?.add_order_and_edit_order?.return_address?.select_country}</option>
                                                            {countryList?.map((country, index) => {
                                                                return <option value={country.country_code} key={index}> {country.name}</option>
                                                            })}
                                                        </select>
                                                        <div className="errorMessage">
                                                            <Message type="errorMsg" text={errorMessages[ErrorMessage({ fieldName: 'country', errors })] || ErrorMessage({ fieldName: 'country', errors })} showMessage={showErrorMessage} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="saveAddressBtn text-right mt-3">
                                                <button className="btn btn-primary" onClick={saveReturnAddress}>{order_page_labels?.add_order_and_edit_order?.return_address?.btn}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="card card-default mt-5">
                            <div className="card-header card-header-border-bottom">
                                <h2>{order_page_labels?.add_order_and_edit_order?.payment_block?.title}</h2>
                            </div>
                            <div className="card-body card-header-border-bottom">
                                <div className="list-unstyled mt-3">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <label className="form-label mandatory_field">{order_page_labels?.add_order_and_edit_order?.payment_block?.payment_mode}</label>
                                            <select className="form-control" name='payment_mode' onChange={onChangePaymentInfo} value={paymnetInfo.payment_mode}>
                                                {payment_option &&
                                                    payment_option?.map((optionString, index) => {
                                                        const [name, value] = optionString.split(',');
                                                        return <option value={name} key={index}>{value}</option>;
                                                    })
                                                }
                                            </select>
                                        </div>

                                        <div className="col-lg-6 reference-input">
                                            <label className="form-label ">{order_page_labels?.add_order_and_edit_order?.payment_block?.payment_status}</label>
                                            <input type="text" disabled className="form-control disabled_input payment_status" maxLength="25" name="check" value={order_page_labels?.add_order_and_edit_order?.payment_block?.default_payment_status} />
                                        </div>

                                    </div>
                                </div>
                                {paymnetInfo?.payment_mode == "PMNT-MD-CHECK" &&
                                    <div className="col-lg-6 reference-input mt-4">
                                        <label className="form-label mandatory_field">{order_page_labels?.add_order_and_edit_order?.payment_block?.check_no}</label>
                                        <input type="number" className="form-control" name="reference" value={paymnetInfo?.reference} onChange={onChangePaymentInfo} />
                                        <Message type="errorMsg" text={errorMessages[ErrorMessage({ fieldName: 'reference', errors })] || ErrorMessage({ fieldName: 'reference', errors })} showMessage={showErrorMessage} style={{ textAlign: 'end' }} />
                                    </div>
                                }
                                {paymnetInfo?.payment_mode == "PMNT-MD-ACT-TRANSFER" &&
                                    <div className="row">
                                        <div className="col-lg-6 reference-input mt-4">
                                            <label className="form-label mandatory_field">{order_page_labels?.add_order_and_edit_order?.payment_block?.reference}</label>
                                            <input type="number" className="form-control" name="reference" value={paymnetInfo?.reference} onChange={onChangePaymentInfo} />
                                            <Message type="errorMsg" text={errorMessages[ErrorMessage({ fieldName: 'reference', errors })] || ErrorMessage({ fieldName: 'reference', errors })} showMessage={showErrorMessage} style={{ textAlign: 'end' }} />
                                        </div>
                                        <div className="col-lg-6 reference-input mt-4">
                                            <label className="form-label mandatory_field">{order_page_labels?.add_order_and_edit_order?.payment_block?.transaction_date}</label>
                                            <input type="date" className="form-control" name="transaction_date" value={paymnetInfo?.transaction_date} onChange={onChangePaymentInfo} />
                                            <Message type="errorMsg" text={errorMessages[ErrorMessage({ fieldName: 'transaction_date', errors })] || ErrorMessage({ fieldName: 'transaction_date', errors })} showMessage={showErrorMessage} style={{ textAlign: 'end' }} />
                                        </div>
                                    </div>
                                }
                                <div className="billingAddressWrapper mt-4">
                                    <p className="mb-2 mandatory_field">{order_page_labels?.add_order_and_edit_order?.billing_address?.title}</p>
                                    <div className="addressList isShippingAddress">
                                        <input type="checkbox" name="is_same_shipping_address" id="is_same_shipping_address" role="button" disabled={Object.keys(returnAddress).length == 0 || returnAddress?.line_1 == null ? true : ""}
                                            onChange={() => setIsSameAsReturnAddress(!isSameAsReturnAddress)} value={isSameAsReturnAddress} checked={isSameAsReturnAddress ? 'checked' : ''} />
                                        <label className="checkBoxLabel" htmlFor="is_same_shipping_address">{order_page_message?.same_as_return_address}</label>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-9">
                                            {isSameAsReturnAddress == false ?
                                                billingAddress.line_1 != null &&
                                                <>
                                                    {billingAddress.line_1 == "NULL" ? "" : billingAddress?.line_1}<br />
                                                    <span className={`${billingAddress?.line_2 ? "display_line_2" : ""}`}>{billingAddress?.line_2 == "NULL" ? "" : billingAddress?.line_2} </span>
                                                    {billingAddress.city == "NULL" ? "" : billingAddress?.city}<br />
                                                    {billingAddress.state == "NULL" ? "" : billingAddress?.state} - {billingAddress.zip == "NULL" ? "" : billingAddress?.zip} <br />
                                                    {billingAddress.country == "NULL" ? "" : countries[billingAddress.country]}
                                                </>
                                                :
                                                <>

                                                    {returnAddress?.line_1 == "NULL" ? "" : returnAddress?.line_1}<br />
                                                    <span className={`${returnAddress?.line_2 ? "display_line_2" : ""} `}>{returnAddress?.line_2 == "NULL" ? "" : returnAddress?.line_2} </span>
                                                    {returnAddress.city == "NULL" ? "" : returnAddress?.city}<br />
                                                    {returnAddress.state == "NULL" ? "" : returnAddress?.state} - {returnAddress.zip == "NULL" ? "" : returnAddress?.zip}<br />
                                                    {returnAddress.country == "NULL" ? "" : countries[returnAddress.country]}
                                                </>
                                            }
                                            {isSameAsReturnAddress == false &&
                                                billingAddress.line_1 == null &&
                                                <div className="billingAddress" onClick={() => setMode("openBillingAddressModal")}><span className="mdi mdi-plus pr-2"></span>{order_page_labels?.add_order_and_edit_order?.billing_address?.add_btn}</div>
                                            }
                                            <div className="mt-3 mb-3">
                                                <div className="errorMessage">
                                                    <Message type="errorMsg" text={errorMessages[ErrorMessage({ fieldName: 'billing_address', errors })]} showMessage={showErrorMessage} />
                                                </div>
                                            </div>
                                        </div>
                                        <Message type="errorMsg" text={errorMessages?.[required_fields?.billingAddress]} showMessage={showErrorMessage} style={{ position: "absolute", right: "20px", top: "63%" }} />
                                        {isSameAsReturnAddress == false &&
                                            <div className="col-lg-3 text-right">
                                                <span className="mdi mdi-account-edit icon" onClick={() => setMode('openBillingAddressModal')}></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                {mode == 'openBillingAddressModal' &&
                                    <div className="addressModal">
                                        <div className="addressModalContent">
                                            <div className="addressModalHeader">
                                                <div className="row justify-content-between">
                                                    <div className="col-lg-6">
                                                        <h5 className="text-dark">{order_page_labels?.add_order_and_edit_order?.billing_address?.title}</h5>
                                                    </div>
                                                    <div className="col-lg-6 text-right">
                                                        <div className="closeRecipientModal text-right" onClick={() => hideErrorMessage("closeBillingAddressModal")}><span className="mdi mdi-close pr-2" role="button"></span></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="addressModalBody">
                                                <div className="row mt-4">
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label mandatory_field">{order_page_labels?.add_order_and_edit_order?.billing_address?.line_1}</label>
                                                            <input type="text" className="form-control" name='line_1' value={billingAddressFormValues['line_1'] == "NULL" ? "" : billingAddressFormValues?.line_1 || ""} maxLength="200" onChange={onChangeBillingAddressFormValues} />
                                                            <div className="errorMessage">
                                                                <Message type="errorMsg" text={errorMessages[ErrorMessage({ fieldName: 'line_1', errors })] || ErrorMessage({ fieldName: 'line_1', errors })} showMessage={showErrorMessage} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label">{order_page_labels?.add_order_and_edit_order?.billing_address?.line_2}</label>
                                                            <input type="text" className="form-control" name='line_2' value={billingAddressFormValues['line_2'] == "NULL" ? "" : billingAddressFormValues?.line_2 || ""} maxLength="200" onChange={onChangeBillingAddressFormValues} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label mandatory_field">{order_page_labels?.add_order_and_edit_order?.billing_address?.city}</label>
                                                            <input type="text" className="form-control" name='city' value={billingAddressFormValues['city'] == "NULL" ? "" : billingAddressFormValues?.city || ""} maxLength="30" onChange={onChangeBillingAddressFormValues} />
                                                            <div className="errorMessage">
                                                                <Message type="errorMsg" text={errorMessages[ErrorMessage({ fieldName: 'city', errors })] || ErrorMessage({ fieldName: 'city', errors })} showMessage={showErrorMessage} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label mandatory_field">{order_page_labels?.add_order_and_edit_order?.billing_address?.state}</label>
                                                            <input type="text" className="form-control" name='state' value={billingAddressFormValues['state'] == "NULL" ? "" : billingAddressFormValues?.state || ""} maxLength="30" onChange={onChangeBillingAddressFormValues} />
                                                            <div className="errorMessage">
                                                                <Message type="errorMsg" text={errorMessages[ErrorMessage({ fieldName: 'state', errors })] || ErrorMessage({ fieldName: 'state', errors })} showMessage={showErrorMessage} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label mandatory_field">{order_page_labels?.add_order_and_edit_order?.billing_address?.zip}</label>
                                                            <input type="text" className="form-control" name='zip' value={billingAddressFormValues['zip'] == "NULL" ? "" : billingAddressFormValues?.zip || ""} maxLength="20" onChange={onChangeBillingAddressFormValues} />
                                                            <div className="errorMessage">
                                                                <Message type="errorMsg" text={errorMessages[ErrorMessage({ fieldName: 'zip', errors })] || ErrorMessage({ fieldName: 'zip', errors })} showMessage={showErrorMessage} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label mandatory_field">{order_page_labels?.add_order_and_edit_order?.billing_address?.country}</label>
                                                            <select className="form-control overflow-auto" name='country' value={billingAddressFormValues['country'] || ""} onChange={onChangeBillingAddressFormValues}>
                                                                <option hidden>{order_page_labels?.add_order_and_edit_order?.billing_address?.select_country}</option>
                                                                {countryList?.map((country, index) => {
                                                                    return <option value={country.country_code} key={index}> {country.name}</option>
                                                                })}
                                                            </select>
                                                            <div className="errorMessage">
                                                                <Message type="errorMsg" text={errorMessages[ErrorMessage({ fieldName: 'country', errors })] || ErrorMessage({ fieldName: 'country', errors })} showMessage={showErrorMessage} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="saveAddressBtn text-right mt-3">
                                                    <button className="btn btn-primary" onClick={saveBillingAddress}>{order_page_labels?.add_order_and_edit_order?.billing_address?.btn}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="text-right mt-5 payment-btn-wrapper">
                {onOrderLoading && <div className="position-relative">
                    <div className="nav-text  mb-3"><SecondaryLoader className={"order_loader_bottom"} /></div>
                </div>}
                {paymnetInfo.payment_mode != "PMNT-MD-PAYPAL" && <button className="btn btn-primary" disabled={payButton} onClick={() => createOrder("save-and-pay")}>{order_page_labels?.add_order_and_edit_order?.add_order_pay_stripe_btn}</button>}
                {paymnetInfo.payment_mode == "PMNT-MD-PAYPAL" && <button className="btn btn-primary" disabled={payButton} onClick={() => createOrder("save-and-pay")}>{order_page_labels?.add_order_and_edit_order?.add_order_pay_paypal_btn}</button>}
                <button className="btn btn-primary ml-3" onClick={() => createOrder("save")}>{order_page_labels?.add_order_and_edit_order?.create_btn}</button>
            </div>

        </React.Fragment >
    )
} 