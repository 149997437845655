import React, { useState, useEffect } from "react";
import { scrollToTop } from "../../Global/Scroll"
import { useParams, useNavigate } from "react-router-dom";
import { errorMessages } from "../../../Constants/Errors";
import { countries } from "../../../Constants/Countries/countries";
import { order_page_message } from "../../../Constants/Messages";
import { callAPI, useStore, usePage, useUser } from "../../../Utils/utils";
import { CardConfig } from "../../../Config/CardConfig";
import { order_page_labels } from "../../../Constants/Labels"
import ErrorMessage from "../../Global/ErrorMessage";
import moment from "moment";
import Message from "../../Message/Message";
import OrderLogs from "./OrderLogs/OrderLogs"
import SecondaryLoader from "../../Global/SecondaryLoader/SecondaryLoader";
import PaymentHistory from "./PaymentHistory/PaymentHistory"
import "./EditOrder.css";

export default function EditOrder({ onSaveOrder }) {

    var { id, orderid } = useParams();
    const navigate = useNavigate();
    const { page, setPage } = usePage()
    const { user, setUser } = useUser();
    const [errors, setErrors] = useState()
    const [loading, setLoading] = useState(false)
    const { store, setStore } = useStore()
    const [orderData, setOrderData] = useState({})
    const [billingAddress, setBillingAddress] = useState({})
    const [countryList, SetCountryList] = useState()
    const [paymnetInfo, setPaymentInfo] = useState("")
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [modifiedFormValues, setModifiedFormValues] = useState({})
    const [returnAddress, setReturnAddress] = useState({})
    const [modifiedReturnAddress, setModifiedReturnAddress] = useState({})
    const [modifiedBillingAddress, setModifiedBillingAddress] = useState({})
    const [modifiedOrderStatus, setModifiedOrderStatus] = useState({})
    const [returnAddressFormvalues, setReturnAddressFormValues] = useState({})
    const [billingAddressFormValues, setBillingAddressFormValues] = useState({})
    const [shippedDates, setShippedDates] = useState([]);
    const [storeName, setStoreName] = useState([]);
    const [onOrderLoading, setOnOrderLoading] = useState(false);
    const [orderTabs, setOrderTabs] = useState("orders");
    const [payment, setPayment] = useState();
    const [mode, setMode] = useState('')
    const [donationAmount, setDonationAmonut] = useState(0)
    const [requested_send_date, setRequestedSendDate] = useState()
    var order_id = orderid ? orderid : id
    var storeId = store?.id

    var order_payment_status = String(paymnetInfo?.payment_status) == "PMNT-STS-PAID" ? "Paid" : "Unpaid"

    useEffect(() => {
        if (storeName?.length == 0 || storeName == undefined) {
            setStoreName(() => [store?.name])
        }
        else {
            setStoreName((existingStoreId) => [...existingStoreId, store?.name])
        }
    }, [store?.id])

    useEffect(() => {
        if (storeName[1] != undefined) {
            if (storeName[0] != storeName[1]) {
                navigate("/admin")
                setStoreName([])
            }
        }
    }, [storeName])


    //get country list
    useEffect(() => {
        callAPI("/admin/address/list",
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                },
            }, navigate, setLoading)
            .then(data => {
                if (data.success) {
                    SetCountryList(data?.records);
                }
            })
    }, [id])

    useEffect(() => {
        setPage({ isLoading: loading })
    }, [loading])

    useEffect(() => {
        if (order_id) {
            callAPI(`/admin/orders/${order_id}`,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': user?.auth_token
                    }
                }, navigate, setLoading, setStore)
                .then(data => {
                    if (data) {
                        // console.log("data", data)
                        setOrderData(data?.record)
                        setRequestedSendDate(data?.record?.requested_send_date?.toString())
                        setDonationAmonut(data?.record?.additional_donation)
                        setReturnAddress(Object.fromEntries(
                            Object.entries(data.record?.return_address).filter(([_, value]) => value !== null && value !== "null" && value !== "" && value !== 0 && value !== "0")
                        ))
                        setBillingAddress(Object.fromEntries(
                            Object.entries(data.record?.billing_address).filter(([_, value]) => value !== null && value !== "null" && value !== "" && value !== 0 && value !== "0")
                        ))
                        // setBillingAddress(data.record?.billing_address)
                        setPaymentInfo(data.record?.payment)
                        var return_address = Object.fromEntries(
                            Object.entries(data.record?.return_address).filter(([_, value]) => value !== null && value !== "null" && value !== "" && value !== 0 && value !== "0")
                        )
                        //store return address data to returnaddressFormvalues for getting new values when the data comes in modal
                        returnAddressFormvalues.line_1 = return_address?.line_1
                        returnAddressFormvalues.line_2 = return_address?.line_2
                        returnAddressFormvalues.city = return_address?.city
                        returnAddressFormvalues.state = return_address?.state
                        returnAddressFormvalues.zip = return_address?.zip
                        returnAddressFormvalues.country = return_address?.country
                        setReturnAddressFormValues(returnAddressFormvalues)
                        var billing_address = Object.fromEntries(
                            Object.entries(data.record?.billing_address).filter(([_, value]) => value !== null && value !== "null" && value !== "" && value !== 0 && value !== "0")
                        )
                        //store billing address data to billingaddressFormvalues for getting new values when the data comes in modal
                        billingAddressFormValues.line_1 = billing_address?.line_1
                        billingAddressFormValues.line_2 = billing_address?.line_2
                        billingAddressFormValues.city = billing_address?.city
                        billingAddressFormValues.state = billing_address?.state
                        billingAddressFormValues.zip = billing_address?.zip
                        billingAddressFormValues.country = billing_address?.country
                        setBillingAddressFormValues(billingAddressFormValues)
                    }
                })

            var SavedMessage = localStorage.getItem('Message')
            if (SavedMessage == null || SavedMessage == "null" || SavedMessage == "Recipients" && SavedMessage != "Order created successfully") {
                localStorage.setItem('Message', "order")
            }
        }
    }, [order_id])

    useEffect(() => {
        if (paymnetInfo?.payment_mode == "PMNT-MD-CR-CD" && paymnetInfo?.payment_mode == "PMNT-STS-PAID") {
            if (paymnetInfo?.cc_last_4_digits?.length == 4) {
                setPayment(true)
            }
            else if (paymnetInfo?.cc_last_4_digits?.length != 4) {
                setPayment(false)
            }
        }
        else if (paymnetInfo?.payment_mode != "PMNT-MD-CR-CD" && paymnetInfo?.payment_mode != "PMNT-STS-PAID") {
            setPayment(true)
        }

    }, [paymnetInfo?.payment_mode])

    useEffect(() => {
        var orderStatus = []
        if (orderData?.status) {
            if (orderStatus?.length == 0) {
                orderStatus.push(orderData?.status)
            }
            else {
                orderStatus = [...orderData?.status, orderData?.status]
            }
        }
    }, [orderData?.status])

    const onChangeField = (event) => {
        setModifiedFormValues({ ...modifiedFormValues, [event.target.name]: event.target.value, })
        setOrderData({ ...orderData, [event.target.name]: event.target.value })
        setModifiedOrderStatus({ ...modifiedOrderStatus, [event.target.name]: event.target.value })
        setPaymentInfo({ ...paymnetInfo, [event.target.name]: event.target.value })
        hideErrorMessage()
    }

    const onChangeShippedDates = (event, lineItemId) => {
        if (event && lineItemId) {
            // Clone the orderData to avoid mutating the state directly
            const updatedOrderData = { ...orderData };

            // Find the line_item in the line_items array with the specified lineItemId
            const lineItemToUpdate = updatedOrderData?.line_items?.find(
                (lineItem) => lineItem?.line_item_id == lineItemId
            );

            // Update the shipped_date property of the found line_item
            if (lineItemToUpdate) {
                lineItemToUpdate.shipped_date = event.target.value;
            }

            // Update the orderData state with the modified data
            setOrderData(updatedOrderData);

            // Clone the shippedDates array to avoid mutating the state directly
            const updatedShippedDates = [...shippedDates];

            // Find the index of the object with the specified lineItemId in the updatedShippedDates array
            const indexToUpdate = updatedShippedDates.findIndex(
                (item) => item.lineItemId == lineItemId
            );

            // Create a new object with the updated shipped_date
            const updatedShippedDate = {
                line_item_id: parseInt(lineItemId),
                shipped_date: moment(event.target?.value).format("YYYY-MM-DD")
            };

            if (indexToUpdate != -1) {
                // If the object with lineItemId exists, update its shipped_date
                updatedShippedDates[indexToUpdate] = updatedShippedDate;
            } else {
                // If the object with lineItemId doesn't exist, add it to the array
                updatedShippedDates.push(updatedShippedDate);
            }

            // Update the shippedDates state with the modified data
            setShippedDates(updatedShippedDates);
        }
    };

    const onChangeBillingAddressFormValues = (event) => {
        modifiedFormValues.order_id = order_id
        setModifiedFormValues({ ...modifiedFormValues, [event.target.name]: event.target.value })
        setBillingAddressFormValues({ ...billingAddressFormValues, [event.target.name]: event.target.value })
        setModifiedBillingAddress({ ...modifiedBillingAddress, [event.target.name]: event.target.value })
        hideErrorMessage()
    }

    const onChangeReturnAddressFormValues = (event) => {
        modifiedFormValues.order_id = order_id
        setModifiedFormValues({ ...modifiedFormValues, [event.target.name]: event.target.value })
        setReturnAddressFormValues({ ...returnAddressFormvalues, [event.target.name]: event.target.value })
        setModifiedReturnAddress({ ...modifiedReturnAddress, [event.target.name]: event.target.value })
        hideErrorMessage()
    }

    const saveOrder = (save) => {

        save != "save-and-pay" && scrollToTop()
        setOnOrderLoading(true)
        var size = Object.keys(modifiedFormValues).length
        modifiedFormValues.order_id = order_id
        modifiedFormValues.order_mode = String(save)

        if (orderData?.payment?.payment_status != "PMNT-STS-PAID" && orderData?.payment?.payment_mode == paymnetInfo.payment_mode) {
            if (orderData?.payment?.payment_mode == null && orderData?.payment?.payment_mode == paymnetInfo.payment_mode) {
                modifiedFormValues.payment = {
                    "payment_mode": "PMNT-MD-STRIPE"
                }
            } else {
                modifiedFormValues.payment = {
                    "payment_mode": paymnetInfo.payment_mode,
                }
            }

        } else if (orderData?.payment?.payment_status != "PMNT-STS-PAID" && orderData?.payment?.payment_mode != paymnetInfo.payment_mode) {
            if (paymnetInfo?.payment_mode == "PMNT-MD-CHECK" && paymnetInfo.reference) {
                modifiedFormValues.payment = {
                    "payment_mode": paymnetInfo.payment_mode,
                    "reference": paymnetInfo.reference
                }
            }
            else if (paymnetInfo?.payment_mode == "PMNT-MD-CHECK" && !paymnetInfo.reference) {
                modifiedFormValues.payment = {
                    "payment_mode": paymnetInfo.payment_mode,
                    "reference": null
                }
            }
            else if (paymnetInfo?.payment_mode == "PMNT-MD-ACT-TRANSFER" && paymnetInfo.reference && paymnetInfo.transaction_date) {
                modifiedFormValues.payment = {
                    "payment_mode": paymnetInfo.payment_mode,
                    "reference": paymnetInfo.reference,
                    "transaction_date": paymnetInfo.transaction_date
                }
            }
            else if (paymnetInfo?.payment_mode == "PMNT-MD-ACT-TRANSFER" && !paymnetInfo.reference || !paymnetInfo.transaction_date) {
                modifiedFormValues.payment = {
                    "payment_mode": paymnetInfo.payment_mode,
                    "reference": paymnetInfo.reference || null,
                    "transaction_date": paymnetInfo.transaction_date || null
                }
            }
            else {
                modifiedFormValues.payment = {
                    "payment_mode": paymnetInfo.payment_mode,
                }
            }
        }

        if (Object.keys(modifiedBillingAddress).length != 0) {
            modifiedFormValues.billing_address = {
                "line_1": billingAddress.line_1,
                "line_2": billingAddress.line_2 ? billingAddress.line_2 : "",
                "city": billingAddress.city,
                "state": billingAddress.state,
                "zip": billingAddress.zip,
                "country": billingAddress.country
            }
        }
        if (Object.keys(modifiedReturnAddress).length != 0) {
            modifiedFormValues.return_address = {
                "line_1": returnAddress.line_1,
                "line_2": returnAddress.line_2 ? returnAddress.line_2 : "",
                "city": returnAddress.city,
                "state": returnAddress.state,
                "zip": returnAddress.zip,
                "country": returnAddress.country
            }
        }
        if (Object.keys(modifiedOrderStatus).length != 0) {
            modifiedFormValues.status = orderData.status
        }
        if (shippedDates?.length != 0) {
            modifiedFormValues.line_items = shippedDates
        }

        setPage({ isLoading: true })
        modifiedFormValues.store_id = storeId
        // console.log("modifiedFormValues", modifiedFormValues)
        // Convert modifiedFormValues to a JSON string
        if (payment) {
            callAPI('/admin/orders/update',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': user?.auth_token
                    },
                    body: JSON.stringify(modifiedFormValues)
                }, navigate, setLoading, setStore)
                .then(data => {
                    // console.log("API responce - " + JSON.stringify(data))
                    if (data.success) {
                        // setOnOrderLoading(false)
                        if (save == "save-and-pay" && paymnetInfo?.payment_mode == "PMNT-MD-STRIPE") {
                            var url = data?.payment_url
                            window.open(url, '_self');
                        }
                        else if (save == "save-and-pay" && paymnetInfo?.payment_mode == "PMNT-MD-PAYPAL") {
                            navigate(`/admin/order/${order_id}/paypal`)
                            localStorage.setItem('order_id', order_id)
                        }
                        onSaveOrder("success")
                        setPage({ isLoading: false })
                    }
                    else {
                        setOnOrderLoading(false)
                        setErrors(data?.errors)
                        setShowErrorMessage(true)
                        onSaveOrder("error")
                        setPage({ isLoading: false })
                    }
                }
                )
        }
    }

    const deleteOrder = () => {
        var data = {
            "order_id": orderData.order_id
        }
        callAPI('/admin/orders/delete',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                },
                body: JSON.stringify(data)
            }, navigate, setLoading, setStore)
            .then(data => {
                if (data.success) {
                    navigate("/admin/orders")
                    localStorage.setItem('Message', order_page_message?.delete_message)
                }
                else {
                    onSaveOrder("deleteError")
                }
            })

    }

    const verifyAddress = (address) => {

        if (address == "returnAddressFormvalues") {
            var address_validation = returnAddressFormvalues
        }

        if (address == "billingAddressFormValues") {
            var address_validation = billingAddressFormValues
        }

        if (address_validation) {
            callAPI(`/admin/address/validate`,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': user?.auth_token
                    },
                    body: JSON.stringify(address_validation)
                }, navigate, setLoading, setStore)
                .then(data => {
                    // console.log("data",data.success)
                    if (data.success) {
                        if (address == "billingAddressFormValues") {
                            setBillingAddress({ ...billingAddress, ...billingAddressFormValues })
                        }
                        if (address == "returnAddressFormvalues") {
                            setReturnAddress({ ...returnAddress, ...returnAddressFormvalues })
                        }
                        setMode("closeBillingAddressModal")
                    }
                    else {
                        setShowErrorMessage(true)
                        setErrors(data.errors)
                        // console.log("data.errors",data.errors)

                    }
                })
        }
    }
    //set return address and validate form fields
    const saveReturnAddress = () => {
        verifyAddress("returnAddressFormvalues")
    }

    //set billing address and validate form fields
    const saveBillingAddress = () => {
        verifyAddress("billingAddressFormValues")
    }

    const ordertToggle = (currentTab) => {
        setOrderTabs(currentTab)
    }

    const filteredOptions = Object?.entries(order_page_labels?.add_order_and_edit_order?.order_status)?.filter(
        ([key, value]) => {
            if (order_page_labels?.add_order_and_edit_order?.order_status?.[orderData?.status] == "In Process") {
                return value != "In Process" && value != "Confirmed";
            } else if (order_page_labels?.add_order_and_edit_order?.order_status?.[orderData?.status] == "Confirmed") {
                return value != "Confirmed"
            }
            return true;
        }
    );

    const hideErrorMessage = (message) => {

        if (message == "closeReturnAddressModal") {
            setMode('closeReturnAddressModal')
        }

        if (message == "closeBillingAddressModal") {
            setMode('closeBillingAddressModal')
        }

        setShowErrorMessage(false)

    }

    //get form values for payments
    const onChangePaymentInfo = (event) => {
        setPaymentInfo({ ...paymnetInfo, [event.target.name]: event.target.value })
        hideErrorMessage()
    }

    const payment_option = Object.keys(order_page_labels?.add_order_and_edit_order?.payment_block?.payment_mode_option)?.map((name) => {
        const value = order_page_labels?.add_order_and_edit_order?.payment_block?.payment_mode_option[name];
        return `${name},${value}`;
    });

    var payButton = true
    if (orderData?.payment?.payment_status != "PMNT-STS-PAID" && paymnetInfo?.payment_mode == "PMNT-MD-STRIPE") {
        payButton = false
    }
    else if (orderData?.payment?.payment_status != "PMNT-STS-PAID" && paymnetInfo?.payment_mode == "PMNT-MD-PAYPAL") {
        payButton = false
    }
    else if (orderData?.payment?.payment_status != "PMNT-STS-PAID" && paymnetInfo?.payment_mode == null || paymnetInfo?.payment_mode == undefined) {
        payButton = false
    }
    else {
        payButton = true
    }


    var current_order_status = "";
    if (orderData?.status == "ORD-STS-PENDING") {
        current_order_status = "Pending"
    }
    else if (orderData?.status == "ORD-STS-PARTIALLY-SHIPPED") {
        current_order_status = "Partially Shipped"
    }
    else if (orderData?.status == "ORD-STS-SHIPPED") {
        current_order_status = "Shipped"
    }

    const navigateToCustomer = () => {
        navigate(`/admin/customer/edit/${orderData?.customer_id}`)
        localStorage.setItem('Message', null)
    }

    return (
        <React.Fragment>

            <div className="saveButton">
                {onOrderLoading || !paymnetInfo.payment_mode ? <div className="position-relative">
                    <div className="nav-text"><SecondaryLoader className={"order_loader_top"} /></div>
                </div> : ""}
                {orderData.status == "ORD-STS-PENDING" && <button className="btn delectBtn mr-3" onClick={() => deleteOrder()}>{order_page_labels?.add_order_and_edit_order?.delete_order}</button>}
                {paymnetInfo.payment_mode != "PMNT-MD-PAYPAL" && <button className="btn btn-primary" disabled={payButton} onClick={() => saveOrder("save-and-pay")}>{order_page_labels?.add_order_and_edit_order?.edit_order_pay_stripe_btn}</button>}
                {paymnetInfo.payment_mode == "PMNT-MD-PAYPAL" && <button className="btn btn-primary" disabled={payButton} onClick={() => saveOrder("save-and-pay")}>{order_page_labels?.add_order_and_edit_order?.edit_order_pay_paypal_btn}</button>}
                <button className="btn btn-primary ml-3" onClick={() => saveOrder("save")}>{order_page_labels?.add_order_and_edit_order?.save_btn}</button>
            </div>

            <div className="col-12 mb-2">
                <div className="card card-default">
                    <div>
                        <ul className="nav nav-tabs px-3 px-xl-5 nav-style-border" >
                            <li className="nav-item">
                                <span className={`nav-link ${orderTabs == "orders" ? 'active' : ''}`} onClick={() => ordertToggle("orders")}>{order_page_labels?.add_order_and_edit_order?.edit_order_tabs?.orders}</span>
                            </li>
                            <li className="nav-item" >
                                <span className={`nav-link ${orderTabs == "OrderLogs" ? 'active' : ''}`} onClick={() => ordertToggle("OrderLogs")}>{order_page_labels?.add_order_and_edit_order?.edit_order_tabs?.order_logs}</span>
                            </li>
                            <li className="nav-item">
                                <span className={`nav-link ${orderTabs == "paymentHistory" ? 'active' : ''}`} onClick={() => ordertToggle("paymentHistory")}>{order_page_labels?.add_order_and_edit_order?.edit_order_tabs?.payment_history}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {orderTabs == "orders" &&
                <div className="row editOrderPage" >
                    <div className="col-lg-12">
                        <div className="card card-default w-25">
                            <div className="card-header card-header-border-bottom mandatory_field">
                                <h2>{order_page_labels?.add_order_and_edit_order?.status_block_title}</h2>
                            </div>
                            <div className="card-body">
                                {!orderData?.status ?
                                    <div className="order_status_loader_container">
                                        <div className="nav-text"><SecondaryLoader className={"order_status_loader"} /></div>
                                    </div> :
                                    <>
                                        {orderData?.status && current_order_status != "" ?
                                            <input type="text" disabled className="form-control disabled_input payment_status" value={current_order_status} />
                                            :
                                            <>
                                                <select className="form-control" name='status' value={orderData?.status || ""} onChange={onChangeField}>
                                                    {orderData?.status && <option hidden>{order_page_labels?.add_order_and_edit_order?.order_status[orderData?.status]}</option>}
                                                    {filteredOptions?.map(([key, value], index) => (
                                                        <option key={index} value={key}>{value}</option>
                                                    ))}
                                                </select>
                                            </>
                                        }
                                    </>}
                                <div className="errorMessage mt-3">
                                    <Message type="errorMsg" text={errors && errorMessages[errors?.find((error) => error?.field_name == "status")?.field_name]} showMessage={showErrorMessage} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-5">
                        <div className="card card-default">
                            <div className="card-body">
                                <div className="selectedCustomer">
                                    <div className="row justify-content-between">
                                        <div className="col-lg-4">
                                            <p className="nav-text text-dark">{order_page_labels?.add_order_and_edit_order?.customer_block?.title}</p>
                                        </div>
                                        <div className="col-lg-4">
                                            <p className="nav-text">{orderData?.first_name == "NULL" || orderData?.first_name == null || orderData?.first_name == "null" || orderData?.first_name == 0 || orderData?.first_name == "0" || !orderData?.first_name ? "" : orderData?.first_name} {orderData?.last_name == "NULL" || orderData?.last_name == null || orderData?.last_name == "null" || orderData?.last_name == 0 || orderData?.last_name == "0" || !orderData?.last_name ? "" : orderData?.last_name} </p>
                                        </div>
                                        <div className="col-lg-4 customer_navigation_arrow">
                                            <svg fill="#343a40" height="20px" width="20px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xmlSpace="preserve" onClick={() => navigateToCustomer()}>
                                                <path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
                                                    c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
                                                    C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
                                                    C255,161.018,253.42,157.202,250.606,154.389z" strokeWidth="2" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-5">
                        <div className="card card-default">
                            <div className="card-header card-header-border-bottom">
                                <h2>{order_page_labels?.add_order_and_edit_order?.lineItems_tableHeaders?.title}</h2>
                            </div>
                            <div className="card-body">
                                {!orderData?.line_items ?
                                    <div className="order_status_loader_container">
                                        <div className="nav-text"><SecondaryLoader className={"order_status_loader"} /></div>
                                    </div> :
                                    <div className="productSearchWrapper">
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th className="col-lg-1">{order_page_labels?.add_order_and_edit_order?.lineItems_tableHeaders?.image}</th>
                                                        <th className="col-lg-2">{order_page_labels?.add_order_and_edit_order?.lineItems_tableHeaders?.product}</th>
                                                        <th className="col-lg-3">{order_page_labels?.add_order_and_edit_order?.lineItems_tableHeaders?.recipient}</th>
                                                        <th className="col-lg-3">
                                                            <div>
                                                                {order_page_labels?.add_order_and_edit_order?.lineItems_tableHeaders?.personalization}
                                                            </div>
                                                        </th>
                                                        <th className="col-lg-1">{order_page_labels?.add_order_and_edit_order?.lineItems_tableHeaders?.price}</th>
                                                        <th className="col-lg-1">{order_page_labels?.add_order_and_edit_order?.lineItems_tableHeaders?.quantity}</th>
                                                        <th className="col-lg-2">{order_page_labels?.add_order_and_edit_order?.lineItems_tableHeaders?.total}</th>
                                                        {order_payment_status == "Paid" && <th className="col-lg-2">{order_page_labels?.add_order_and_edit_order?.lineItems_tableHeaders?.shipped_date}</th>}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {orderData?.line_items?.map((data, index) => {
                                                        var cards_settings_basedon_product_type = CardConfig?.cards_settings?.find(card => card?.product_type === data?.product_type)
                                                        return (
                                                            <>
                                                                {cards_settings_basedon_product_type?.getRecipients &&
                                                                    <tr key={index}>
                                                                        <td className="itemImg"><img src={data.path} alt={data.image_name} /></td>
                                                                        <td>{data.name}</td>
                                                                        <td className="recipients" colSpan="2">
                                                                            {data?.recipients?.map((recipient, index1) => {
                                                                                return (
                                                                                    <div key={index1} className="mb-3 addressAndPersonalizationMessageWrapper col-lg-12">
                                                                                        <div className="col-lg-5">
                                                                                            {recipient?.salutation == "NULL" || recipient?.salutation == null || recipient?.salutation == "null" || recipient?.salutation == 0 || recipient?.salutation == "0" || !recipient?.salutation ? "" : recipient?.salutation} {recipient?.name == "NULL" ? "" : recipient?.name} <br />
                                                                                            <span className={`${recipient?.line_3 ? "display_line_2" : ""}`}>{recipient?.line_3 == "NULL" || recipient?.line_3 == null || recipient?.line_3 == "null" || recipient?.line_3 == 0 || recipient?.line_3 == "0" || !recipient?.line_3 ? "" : recipient?.line_3} </span>
                                                                                            {recipient?.line_1 == "NULL" ? "" : recipient?.line_1} <br />
                                                                                            <span className={`${recipient?.line_2 ? "display_line_2" : ""}`}>{recipient?.line_2 == "NULL" || recipient?.line_2 == null || recipient?.line_2 == "null" || recipient?.line_2 == 0 || recipient?.line_2 == "0" || !recipient?.line_2 ? "" : recipient?.line_2} </span>
                                                                                            {recipient?.city == "NULL" ? "" : recipient?.city}<br />
                                                                                            {recipient?.state == "NULL" ? "" : recipient?.state} - {recipient?.zip == "NULL" ? "" : recipient?.zip}<br />
                                                                                            {recipient?.country == "NULL" ? "" : countries[recipient?.country]}
                                                                                        </div>
                                                                                        <div className="col-lg-1"></div>
                                                                                        <div className="col-lg-5">
                                                                                            {recipient.personalisation == "null" && recipient.personalisation == "NULL" ?
                                                                                                '' : recipient.personalisation
                                                                                            }
                                                                                            <div className="pt-2"> <span className="fw-bold">Sender's name on card : </span><span>{recipient.sender_name_on_card}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                )
                                                                            })}
                                                                        </td>
                                                                        <td>{parseFloat(data.price_per_item).toFixed(2)}</td>
                                                                        <td>{data.quantity}</td>
                                                                        <td>{parseFloat(data.line_item_total_price).toFixed(2)}</td>
                                                                        {order_payment_status == "Paid" && <td><input type="date" className="form-control" name='shipped_date' min={orderData?.order_date} value={data?.['shipped_date'] || ''} onChange={(event) => onChangeShippedDates(event, data?.line_item_id)} /></td>}
                                                                    </tr>
                                                                }
                                                                {cards_settings_basedon_product_type?.fields &&
                                                                    <tr key={index}>
                                                                        <td className="itemImg"><img src={data.path} alt={data.image_name} /></td>
                                                                        <td>{data.name}</td>
                                                                        <td className="recipients">
                                                                            {data?.customization?.fields?.map((field, index1) => {
                                                                                return (
                                                                                    <div key={index1}>
                                                                                        {field?.name ? <p className="mb-0">{field?.name}</p> : <p className="mb-0">{field?.name_personalization}</p>}
                                                                                        {field.email && <p className="mb-0">{field.email}</p>}
                                                                                        {field.message && <p className="mb-0">{field.message}</p>}
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </td>
                                                                        <td></td>
                                                                        <td>{parseFloat(data.price_per_item).toFixed(2)}</td>
                                                                        <td>{data.quantity}</td>
                                                                        <td>{parseFloat(data.line_item_total_price).toFixed(2)}</td>
                                                                        {order_payment_status == "Paid" && <td><input type="date" className="form-control" name='shipped_date' min={orderData?.order_date} value={data?.['shipped_date'] || ''} onChange={(event) => onChangeShippedDates(event, data?.line_item_id)} /></td>}
                                                                    </tr>
                                                                }
                                                                {cards_settings_basedon_product_type?.quantity &&
                                                                    <tr key={index}>
                                                                        <td className="itemImg"><img src={data.path} alt={data.image_name} /></td>
                                                                        <td>{data.name}</td>
                                                                        <td className="recipients"> </td>
                                                                        <td> </td>
                                                                        <td>{parseFloat(data.price_per_item).toFixed(2)}</td>
                                                                        <td>{data.quantity}</td>
                                                                        <td>{parseFloat(data.line_item_total_price).toFixed(2)}</td>
                                                                        {order_payment_status == "Paid" && <td><input type="date" className="form-control" name='shipped_date' min={orderData?.order_date} value={data?.['shipped_date'] || ''} onChange={(event) => onChangeShippedDates(event, data?.line_item_id)} /></td>}
                                                                    </tr>
                                                                }
                                                            </>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                        <div className="card card-default mt-5">
                            <div className="card-body card-header-border-bottom">
                                <div className="list-unstyled mt-3">
                                    <div className="row justify-content-between">
                                        <div className="donation_box">
                                            <p>{order_page_message?.donation_amount} </p>
                                            <input
                                                type="number"
                                                name="donation"
                                                className="form-control disabled_input" disabled
                                                value={donationAmount || ""}
                                            />
                                        </div>

                                        <div className="donation_checkbox">
                                            <input type="checkbox" name="donation_checkbox" checked={orderData?.processing_fee > 0 } disabled={true} />
                                            <p>{order_page_message?.donation_checkbox} {store?.name}</p>
                                        </div>
                                        <div className="donation_box">
                                            <div>{order_page_message?.requested_send_date} </div>
                                            <input
                                                type="string"
                                                name="donation"
                                                className="form-control w-25 mx-4 "
                                                value={requested_send_date ? moment(requested_send_date).format("MMM-DD-yyyy"):"Send in next mail cycle"}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card card-default mt-5">
                            <div className="card-body card-header-border-bottom">
                                <div className="list-unstyled mt-3">
                                    <div className="row justify-content-between">
                                        <div className="col-lg-10">
                                            <div className="mid pb-3 text-dark">{order_page_labels?.add_order_and_edit_order?.sub_total_block?.subtotal}</div>
                                        </div>
                                        <div className="col-lg-2 text-right">
                                            <div className="d-inline-block float-right text-default">{orderData?.subtotal}</div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-between">
                                        <div className="col-lg-10">
                                            <div className="mid pb-3 text-dark">{order_page_labels?.add_order_and_edit_order?.sub_total_block?.donation}</div>
                                        </div>
                                        <div className="col-lg-2 text-right">
                                            <div className="d-inline-block float-right text-default">{orderData?.additional_donation}</div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-between">
                                        <div className="col-lg-10">
                                            <div className="mid pb-3 text-dark">{order_page_labels?.add_order_and_edit_order?.sub_total_block?.processing_fees}</div>
                                        </div>
                                        <div className="col-lg-2 text-right">
                                            <div className="d-inline-block float-right text-default">{orderData?.processing_fee}</div>
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                        <div className="col-lg-10">
                                            <div className="mid pb-3 text-dark">{order_page_labels?.add_order_and_edit_order?.sub_total_block?.tax}</div>
                                        </div>
                                        <div className="col-lg-2 text-right">
                                            <div className="d-inline-block float-right text-default">{orderData?.tax}</div>
                                        </div>
                                    </div> */}
                                    <hr className="w-100"></hr>
                                    <div className="row">
                                        <div className="col-lg-10">
                                            <div className="mid pb-3 text-dark">{order_page_labels?.add_order_and_edit_order?.sub_total_block?.total}</div>
                                        </div>
                                        <div className="col-lg-2 text-right">
                                            <div className="d-inline-block float-right text-default">{orderData?.total ? orderData?.total : 0}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card card-default mt-5">
                            <div className="card-header card-heade(r-border-bottom">
                                <h2 className="mandatory_field">{order_page_labels?.add_order_and_edit_order?.return_address?.title}</h2>
                            </div>
                            <div className="card-body card-header-border-bottom">
                                <div className="row">
                                    <div className="col-lg-9">
                                        <div className="customerAddress">
                                            <div className="addressList">
                                                {returnAddress?.first_name === "NULL" ? "" : returnAddress?.first_name} {returnAddress?.last_name == "NULL" ? "" : " " + returnAddress?.last_name}<br />
                                                {returnAddress?.line_1 == "NULL" ? "" : returnAddress?.line_1}<br />
                                                <span className={`${returnAddress?.line_2 ? "display_line_2" : ""} `}>{returnAddress?.line_2 == "NULL" ? "" : returnAddress?.line_2} </span>
                                                {returnAddress.city == "NULL" ? "" : returnAddress?.city}<br />
                                                {returnAddress.state == "NULL" ? "" : returnAddress?.state} - {returnAddress.zip == "NULL" ? "" : returnAddress?.zip}<br />
                                                {returnAddress.country == "NULL" ? "" : countries[returnAddress.country]}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 text-right">
                                        <span className="mdi mdi-account-edit icon" onClick={() => setMode('openReturnAddressModal')}></span>
                                    </div>
                                </div>
                            </div>
                            {mode == 'openReturnAddressModal' &&
                                <div className="addressModal">
                                    <div className="addressModalContent">
                                        <div className="addressModalHeader">
                                            <div className="row justify-content-between">
                                                <div className="col-lg-6">
                                                    <h5 className="text-dark">{order_page_labels?.add_order_and_edit_order?.return_address?.title}</h5>
                                                </div>
                                                <div className="col-lg-6 text-right">
                                                    <div className="closeRecipientModal text-right" role="button" onClick={() => hideErrorMessage("closeReturnAddressModal")}><span className="mdi mdi-close pr-2" role="buttton"></span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="addressModalBody">
                                            <div className="row mt-4">
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="form-label mandatory_field">{order_page_labels?.add_order_and_edit_order?.return_address?.line_1}</label>
                                                        <input type="text" className="form-control" name='line_1' value={returnAddressFormvalues['line_1'] == "NULL" ? "" : returnAddressFormvalues?.line_1 || ""} maxLength="200" onChange={onChangeReturnAddressFormValues} />
                                                        <div className="errorMessage">
                                                            <Message type="errorMsg" text={errorMessages[ErrorMessage({ fieldName: 'line_1', errors })] || ErrorMessage({ fieldName: 'line_1', errors })} showMessage={showErrorMessage} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="form-label">{order_page_labels?.add_order_and_edit_order?.return_address?.line_2}</label>
                                                        <input type="text" className="form-control" name='line_2' value={returnAddressFormvalues['line_2'] == "NULL" ? "" : returnAddressFormvalues?.line_2 || ""} maxLength="200" onChange={onChangeReturnAddressFormValues} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="form-label mandatory_field">{order_page_labels?.add_order_and_edit_order?.return_address?.city}</label>
                                                        <input type="text" className="form-control" name='city' value={returnAddressFormvalues['city'] == "NULL" ? "" : returnAddressFormvalues?.city || ""} maxLength="30" onChange={onChangeReturnAddressFormValues} />
                                                        <div className="errorMessage">
                                                            <Message type="errorMsg" text={errorMessages[ErrorMessage({ fieldName: 'city', errors })] || ErrorMessage({ fieldName: 'city', errors })} showMessage={showErrorMessage} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="form-label mandatory_field">{order_page_labels?.add_order_and_edit_order?.return_address?.state}</label>
                                                        <input type="text" className="form-control" name='state' value={returnAddressFormvalues['state'] == "NULL" ? "" : returnAddressFormvalues?.state || ""} maxLength="30" onChange={onChangeReturnAddressFormValues} />
                                                        <div className="errorMessage">
                                                            <Message type="errorMsg" text={errorMessages[ErrorMessage({ fieldName: 'state', errors })] || ErrorMessage({ fieldName: 'state', errors })} showMessage={showErrorMessage} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="form-label mandatory_field">{order_page_labels?.add_order_and_edit_order?.return_address?.zip}</label>
                                                        <input type="text" className="form-control" name='zip' value={returnAddressFormvalues['zip'] == "NULL" ? "" : returnAddressFormvalues?.zip || ""} maxLength="20" onChange={onChangeReturnAddressFormValues} />
                                                        <div className="errorMessage">
                                                            <Message type="errorMsg" text={errorMessages[ErrorMessage({ fieldName: 'zip', errors })] || ErrorMessage({ fieldName: 'zip', errors })} showMessage={showErrorMessage} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="form-label mandatory_field">{order_page_labels?.add_order_and_edit_order?.return_address?.country}</label>
                                                        <select className="form-control overflow-auto" name='country' value={returnAddressFormvalues['country'] || ""} onChange={onChangeReturnAddressFormValues}>
                                                            <option hidden>{order_page_labels?.add_order_and_edit_order?.return_address?.select_country}</option>
                                                            {countryList?.map((country, index) => {
                                                                return <option value={country.country_code} key={index}> {country.name}</option>
                                                            })}
                                                        </select>
                                                        <div className="errorMessage">
                                                            <Message type="errorMsg" text={errorMessages[ErrorMessage({ fieldName: 'country', errors })] || ErrorMessage({ fieldName: 'country', errors })} showMessage={showErrorMessage} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="saveAddressBtn text-right mt-3">
                                                <button className="btn btn-primary" onClick={saveReturnAddress}>{order_page_labels?.add_order_and_edit_order?.return_address?.btn}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="card card-default mt-5">
                            <div className="card-header card-header-border-bottom">
                                <h2>{order_page_labels?.add_order_and_edit_order?.payment_block?.title}</h2>
                            </div>
                            <div className="card-body card-header-border-bottom">
                                <div className="list-unstyled">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <label className="form-label mandatory_field">{order_page_labels?.add_order_and_edit_order?.payment_block?.payment_mode}</label>
                                            <select className="form-control" name='payment_mode' value={paymnetInfo?.payment_mode || ""} disabled={order_payment_status == "Paid" ? true : false} onChange={onChangePaymentInfo}>
                                                {payment_option &&
                                                    payment_option?.map((optionString, index) => {
                                                        const [name, value] = optionString.split(',');
                                                        return <option value={name} key={index}>{value}</option>;
                                                    })
                                                }
                                            </select>
                                            <div className="errorMessage mt-3">
                                                <Message type="errorMsg" text={errors && errorMessages[errors?.find((error) => error?.field_name == "payment_mode")?.field_name]} showMessage={showErrorMessage} />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <label className="form-label">{order_page_labels?.add_order_and_edit_order?.payment_block?.payment_status}</label>
                                            <input type="text" disabled className="form-control disabled_input payment_status" value={order_payment_status} />
                                        </div>
                                    </div>
                                </div>
                                {paymnetInfo?.payment_mode == "PMNT-MD-CHECK" &&
                                    <div className="col-lg-6 reference-input">
                                        <label className="form-label mandatory_field">{order_page_labels?.add_order_and_edit_order?.payment_block?.check_no}</label>
                                        <input type="number" className="form-control" maxLength="25" name="reference" value={paymnetInfo?.reference} onChange={onChangePaymentInfo} disabled={order_payment_status == "Paid" ? true : false} />
                                        <Message type="errorMsg" text={errorMessages[ErrorMessage({ fieldName: 'reference', errors })] || ErrorMessage({ fieldName: 'reference', errors })} showMessage={showErrorMessage} style={{ textAlign: 'end' }} />
                                    </div>
                                }
                                {paymnetInfo?.reference && paymnetInfo?.transaction_date || paymnetInfo?.payment_mode == "PMNT-MD-ACT-TRANSFER" ?
                                    <div className="row">
                                        <div className="col-lg-6 reference-input mt-4">
                                            <label className="form-label mandatory_field">{order_page_labels?.add_order_and_edit_order?.payment_block?.reference}</label>
                                            <input type="text" className="form-control" name="reference" value={paymnetInfo?.reference} onChange={onChangePaymentInfo} disabled={order_payment_status == "Paid" ? true : false} />
                                            <Message type="errorMsg" text={errorMessages[ErrorMessage({ fieldName: 'reference', errors })] || ErrorMessage({ fieldName: 'reference', errors })} showMessage={showErrorMessage} style={{ textAlign: 'end' }} />
                                        </div>
                                        <div className="col-lg-6 reference-input mt-4">
                                            <label className="form-label mandatory_field">{order_page_labels?.add_order_and_edit_order?.payment_block?.transaction_date}</label>
                                            <input type="date" className="form-control" name="transaction_date" value={paymnetInfo?.transaction_date} onChange={onChangePaymentInfo} disabled={order_payment_status == "Paid" ? true : false} />
                                            <Message type="errorMsg" text={errorMessages[ErrorMessage({ fieldName: 'transaction_date', errors })] || ErrorMessage({ fieldName: 'transaction_date', errors })} showMessage={showErrorMessage} style={{ textAlign: 'end' }} />
                                        </div>
                                    </div> : ""
                                }
                                <div className="billingAddressWrapper mt-4">
                                    <p className="mb-2 mandatory_field">{order_page_labels?.add_order_and_edit_order?.billing_address?.title}</p>
                                    <div className="row">
                                        <div className="col-lg-9">
                                            {billingAddress?.line_1 != null ?
                                                <>

                                                    {returnAddress?.first_name == "NULL" ? "" : billingAddress?.first_name} {billingAddress?.last_name == "NULL" ? "" : " "+ billingAddress?.last_name}<br />
                                                    {billingAddress.line_1 == "NULL" ? "" : billingAddress?.line_1}<br />
                                                    <span className={`${billingAddress?.line_2 ? "display_line_2" : ""}`}>{billingAddress?.line_2 == "NULL" ? "" : billingAddress?.line_2} </span>
                                                    {billingAddress.city == "NULL" ? "" : billingAddress?.city}<br />
                                                    {billingAddress.state == "NULL" ? "" : billingAddress?.state} - {billingAddress.zip == "NULL" ? "" : billingAddress?.zip} <br />
                                                    {billingAddress.country == "NULL" ? "" : countries[billingAddress.country]}
                                                </>
                                                :
                                                <p className="billingAddress" onClick={() => setMode("openBillingAddressModal")}><span className="mdi mdi-plus pr-2"></span>{order_page_labels?.add_order_and_edit_order?.billing_address?.add_btn}</p>
                                            }
                                            <div className="mt-3 mb-3">
                                                <div className="errorMessage">
                                                    <Message type="errorMsg" text={errors && errorMessages[errors?.find((error) => error?.field_name == "billing_address")?.field_name]} showMessage={showErrorMessage} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 text-right">
                                            <span className="mdi mdi-account-edit icon" onClick={() => setMode('openBillingAddressModal')}></span>
                                        </div>
                                    </div>
                                </div>

                                {mode == 'openBillingAddressModal' &&
                                    <div className="addressModal">
                                        <div className="addressModalContent">
                                            <div className="addressModalHeader">
                                                <div className="row justify-content-between">
                                                    <div className="col-lg-6">
                                                        <h5 className="text-dark">{order_page_labels?.add_order_and_edit_order?.billing_address?.title}</h5>
                                                    </div>
                                                    <div className="col-lg-6 text-right">
                                                        <div className="closeRecipientModal text-right" onClick={() => hideErrorMessage("closeBillingAddressModal")}><span className="mdi mdi-close pr-2" role="button"></span></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="addressModalBody">
                                                <div className="row mt-4">
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label mandatory_field">{order_page_labels?.add_order_and_edit_order?.billing_address?.line_1}</label>
                                                            <input type="text" className="form-control" name='line_1' value={billingAddressFormValues['line_1'] == "NULL" ? "" : billingAddressFormValues?.line_1 || ""} maxLength="100" onChange={onChangeBillingAddressFormValues} />
                                                            <div className="errorMessage">
                                                                <Message type="errorMsg" text={errorMessages[ErrorMessage({ fieldName: 'line_1', errors })] || ErrorMessage({ fieldName: 'line_1', errors })} showMessage={showErrorMessage} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label">{order_page_labels?.add_order_and_edit_order?.billing_address?.line_2}</label>
                                                            <input type="text" className="form-control" name='line_2' value={billingAddressFormValues['line_2'] == "NULL" ? "" : billingAddressFormValues?.line_2 || ""} maxLength="100" onChange={onChangeBillingAddressFormValues} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label mandatory_field">{order_page_labels?.add_order_and_edit_order?.billing_address?.city}</label>
                                                            <input type="text" className="form-control" name='city' value={billingAddressFormValues['city'] == "NULL" ? "" : billingAddressFormValues?.city || ""} maxLength="30" onChange={onChangeBillingAddressFormValues} />
                                                            <div className="errorMessage">
                                                                <Message type="errorMsg" text={errorMessages[ErrorMessage({ fieldName: 'city', errors })] || ErrorMessage({ fieldName: 'city', errors })} showMessage={showErrorMessage} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label mandatory_field">{order_page_labels?.add_order_and_edit_order?.billing_address?.state}</label>
                                                            <input type="text" className="form-control" name='state' value={billingAddressFormValues['state'] == "NULL" ? "" : billingAddressFormValues?.state || ""} maxLength="30" onChange={onChangeBillingAddressFormValues} />
                                                            <div className="errorMessage">
                                                                <Message type="errorMsg" text={errorMessages[ErrorMessage({ fieldName: 'state', errors })] || ErrorMessage({ fieldName: 'state', errors })} showMessage={showErrorMessage} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label mandatory_field">{order_page_labels?.add_order_and_edit_order?.billing_address?.zip}</label>
                                                            <input type="text" className="form-control" name='zip' value={billingAddressFormValues['zip'] == "NULL" ? "" : billingAddressFormValues?.zip || ""} maxLength="20" onChange={onChangeBillingAddressFormValues} />
                                                            <div className="errorMessage">
                                                                <Message type="errorMsg" text={errorMessages[ErrorMessage({ fieldName: 'zip', errors })] || ErrorMessage({ fieldName: 'zip', errors })} showMessage={showErrorMessage} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label mandatory_field">{order_page_labels?.add_order_and_edit_order?.billing_address?.country}</label>
                                                            <select className="form-control overflow-auto" name='country' value={billingAddressFormValues['country'] || ""} onChange={onChangeBillingAddressFormValues}>
                                                                <option hidden>{order_page_labels?.add_order_and_edit_order?.billing_address?.select_country}</option>
                                                                {countryList?.map((country, index) => {
                                                                    return <option value={country.country_code} key={index}> {country.name}</option>
                                                                })}
                                                            </select>
                                                            <div className="errorMessage">
                                                                <Message type="errorMsg" text={errorMessages[ErrorMessage({ fieldName: 'country', errors })] || ErrorMessage({ fieldName: 'country', errors })} showMessage={showErrorMessage} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="saveAddressBtn text-right mt-3">
                                                    <button className="btn btn-primary" onClick={saveBillingAddress}>{order_page_labels?.add_order_and_edit_order?.billing_address?.btn}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="text-right mt-5 payment-btn-wrapper">
                            {onOrderLoading || !paymnetInfo.payment_mode ? <div className="position-relative">
                                <div className="nav-text"><SecondaryLoader className={"order_loader_bottom"} /></div>
                            </div> : ""}
                            {paymnetInfo.payment_mode != "PMNT-MD-PAYPAL" && <button className="btn btn-primary" disabled={payButton} onClick={() => saveOrder("save-and-pay")}>{order_page_labels?.add_order_and_edit_order?.edit_order_pay_stripe_btn}</button>}
                            {paymnetInfo.payment_mode == "PMNT-MD-PAYPAL" && <button className="btn btn-primary" disabled={payButton} onClick={() => saveOrder("save-and-pay")}>{order_page_labels?.add_order_and_edit_order?.edit_order_pay_paypal_btn}</button>}
                            <button className="btn btn-primary ml-3" onClick={() => saveOrder("save")}>{order_page_labels?.add_order_and_edit_order?.save_btn}</button>
                        </div>
                    </div>
                </div>
            }

            {orderTabs == "OrderLogs" &&
                <>
                    <OrderLogs />
                </>
            }
            {orderTabs == "paymentHistory" &&
                <>
                    <PaymentHistory />
                </>
            }

        </React.Fragment >
    )
} 